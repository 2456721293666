import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { addListener } from 'devtools-detector';
var flagDevTools;
addListener((isOpen) => {
  flagDevTools = isOpen;
});

@Component({
  selector: 'app-new-nav-bar',
  templateUrl: './new-nav-bar.component.html',
  styleUrls: ['./new-nav-bar.component.scss']
})

export class NewNavBarComponent implements OnInit, OnDestroy {

  devToolsOpen: Boolean = false;
  user;
  fakeUser: boolean = false;
  mobileFlagAppNew: boolean = false;
  mobileFlag: boolean = false;
  modalDownload: boolean = false;
  downloadProgress: number = 0;
  token: string;
  softwareLogin: string;
  service = "Desktop"
  notifications = [];
  userName: string;
  isSmallScreen = false;

  routerLink_URL = environment.url_version == '' ? `${environment.url_version}` : `/${environment.url_version}`;
  routerLink_URL_sobre = `${this.routerLink_URL}/sobre`;
  routerLink_URL_perfilDownloads = `${this.routerLink_URL}/perfil/downloads`;

  showNavbar;

  constructor(
    private _route: Router,
    private appService: AppService,
    private _http: HttpClient,
    private ativatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.initThemeSwitcher();
    this.appService.getFakeLogin().subscribe(flag => {
      this.fakeUser = flag;
    });

    this.appService.getMobileApp().subscribe(flag => {
      this.mobileFlag = flag;
    });

    this.appService.getDownloadModal().subscribe(flag => {
      this.modalDownload = flag;
    });

    this.appService.getDownloadProgress().subscribe(flag => {
      this.downloadProgress = flag;
    });

    this.appService.getMobileAppNew().subscribe(flag => {
      this.mobileFlagAppNew = flag;
    });

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        navigator.userAgent
      )
    )
      this.service = "Mobile";
    else this.service = "Desktop";
    this.softwareLogin = sessionStorage.getItem('software-new');
    this.user = this.appService.getUser().value;

    this.appService.user.subscribe((user) => {
      if (user) {
        this.user = user;
        this.appService.emitShowNavBar(this.appService.getNavBarComLogin());
        this.showNavbar = this.appService.getNavBarComLogin();
      } else {
        this.appService.emitShowNavBar(this.appService.getNavBarSemLogin());
        this.showNavbar = this.appService.getNavBarSemLogin();
      }
    });

    this.ativatedRoute.data.subscribe((info) => {
      if (info.user) this.user = info.user;
    });

    this.appService.showNavBar.subscribe((nav_bar) => {
      this.showNavbar = nav_bar;
    });

    this.checkDevToolsChange();
    this.initMobileMenuInteractions();
    this.checkThemeStatus();

    this.checkScreenSize();
    window.addEventListener('resize', () => {
      this.checkScreenSize();
    });
  }

  ngOnDestroy() {
    window.removeEventListener('resize', () => {
      this.checkScreenSize();
    });
  }

  checkScreenSize() {
    this.isSmallScreen = window.innerWidth <= 500;
  }

  ngAfterViewChecked() {
    if (this.user == null || this.user == undefined || !this.user) {
      return
    }
  }

  getFormatedName(user) {
    try {
      let Fullname = this.user.personalData.name;
      const formataNome = str => {
        return str.toLowerCase().replace(/(?:^|\s)(?!da|de|do)\S/g, l => l.toUpperCase());
      };
      let parts = Fullname.split(' ');
      return `${parts[0]}`;

    } catch (e) {
      this.appService.emit(undefined, undefined, undefined);
      this._route.navigate(['osciloscopio/sinais-filtro']);
    }
  }

  isActive() {
    let retorno;

    if (this._route.url.indexOf('blog-post') !== 0)
      retorno = true;

    if (this._route.url.indexOf('blog') !== 0)
      retorno = true;

    if (this._route.url.indexOf('blog') === -1)
      retorno = false;

    return retorno
  }

  logout() {
    this.appService.logoutUser();
    this._route.navigate([`${environment.url_version}/filtros`]);
  }

  checkDevToolsChange() {
    setInterval(() => {
      if (flagDevTools) {
        if (this.user) {
          if (this.user.crudPermission && this.user.crudPermission.admin) {
            this.devToolsOpen = false;
            return
          }
        }
        this.devToolsOpen = true;
      } else {
        if (this.devToolsOpen) {
          window.location.reload();
        }
        this.devToolsOpen = false;
      }
    }, 1);
  }

  closeModalDownload() {
    this.appService.setDownloadModal(false);
  }

  closeWebView() {
    const url = `${environment.url_version}/close-browser`;
    window.location.href = url;
  }

  toggleMobileMenu() {
    const navContent = document.getElementById('mainNavContent');
    const toggler = document.querySelector('.navbar-toggler');

    if (navContent) {
      navContent.classList.toggle('show');
      void navContent.offsetWidth;
      console.log('Menu mobile toggled:', navContent.classList.contains('show'));
    }

    if (toggler) {
      toggler.classList.toggle('active');
    }

    event.preventDefault();
    event.stopPropagation();
  }

  initThemeSwitcher() {
    setTimeout(() => {
      const themeSwitcher = document.getElementById('themeSwitcher');
      const body = document.body;
      const savedTheme = localStorage.getItem('theme');

      if (savedTheme === 'light') {
        body.classList.add('light-mode');
        document.documentElement.classList.add('light-mode');
      }

      if (themeSwitcher) {
        themeSwitcher.addEventListener('click', () => {
          body.classList.toggle('light-mode');
          document.documentElement.classList.toggle('light-mode');
          if (body.classList.contains('light-mode')) {
            localStorage.setItem('theme', 'light');
          } else {
            localStorage.setItem('theme', 'dark');
          }
        });
      }
    }, 1);
  }

  initMobileMenuInteractions() {
    setTimeout(() => {
      const navbarToggler = document.querySelector('.navbar-toggler');
      if (navbarToggler) {
        navbarToggler.addEventListener('click', () => {
          this.toggleMobileMenu();
        });
      }
    }, 500);
  }

  checkThemeStatus() {
    setInterval(() => {
      const isLightMode = document.body.classList.contains('light-mode');
      const sunIcon: any = document.querySelector('.sun-icon');
      const moonIcon: any = document.querySelector('.moon-icon');

      if (isLightMode) {
        if (sunIcon) sunIcon.style.opacity = '1';
        if (sunIcon) sunIcon.style.transform = 'translateY(0)';
        if (moonIcon) moonIcon.style.opacity = '0';
        if (moonIcon) moonIcon.style.transform = 'translateY(-20px)';
      } else {
        if (sunIcon) sunIcon.style.opacity = '0';
        if (sunIcon) sunIcon.style.transform = 'translateY(20px)';
        if (moonIcon) moonIcon.style.opacity = '1';
        if (moonIcon) moonIcon.style.transform = 'translateY(0)';
      }
    }, 200);
  }
}