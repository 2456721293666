import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  whatsApp = "";
  routerLink_URL = environment.url_version == '' ? `${environment.url_version}` : `/${environment.url_version}`;

  constructor(private _router: Router) { }

  ngOnInit() {
    this.detectar_mobile();
  }

  navigateTo(route: string) {
    if (route) {
      let url: string;
      let queryParams: any = {};
      const baseUrl = this.routerLink_URL.startsWith('/') ? this.routerLink_URL : `/${this.routerLink_URL}`;

      switch (route) {
        case 'filtros':
          url = `${baseUrl}/filtros`;
          queryParams = { typeFilter: 'oscillogram' };
          break;
        case 'diagramas':
          url = `${baseUrl}/filtros`;
          queryParams = { typeFilter: 'diagram' };
          break;
        case 'falhas':
          url = `${baseUrl}/filtros`;
          queryParams = { typeFilter: 'failure' };
          break;
        case 'gerador':
          url = `${baseUrl}/filtros`;
          queryParams = { typeFilter: 'generator' };
          break;
        default:
          url = `${baseUrl}/${route}`;
          break;
      }
      this._router.navigate([url], { queryParams });
    }
  }

  detectar_mobile() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      // Dispositivo móvel
      this.whatsApp = "https://api.whatsapp.com/send?phone=554499818097";
    }
    else {
      // Desktop
      this.whatsApp = "https://web.whatsapp.com/send?phone=554499818097";
    }
  }
}