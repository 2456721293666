import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../environments/environment';
import { SharedService } from './shared/shared.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AppService {
  public token: BehaviorSubject<string> = new BehaviorSubject(null);
  public CurvedLinks: BehaviorSubject<string> = new BehaviorSubject(null);
  public user: BehaviorSubject<any> = new BehaviorSubject(null);
  public notificacao: BehaviorSubject<any> = new BehaviorSubject(null);
  public type: BehaviorSubject<string> = new BehaviorSubject('public');
  public footer: BehaviorSubject<string> = new BehaviorSubject(null);
  public mediaQuery: BehaviorSubject<any> = new BehaviorSubject(null);
  private acceptOrRecuseAlertValue = new BehaviorSubject<boolean>(false);
  private fakeLogin = new BehaviorSubject<boolean>(false);
  private mobileFlagApp = new BehaviorSubject<boolean>(false);
  private mobileFlagAppNew = new BehaviorSubject<boolean>(false);
  private softwareNew = new BehaviorSubject<boolean>(false);
  private mobileFlag = new BehaviorSubject<string>('Desktop');
  private modalDownload = new BehaviorSubject<boolean>(false);
  private downloadProgress = new BehaviorSubject<number>(0);
  private loginUser = new BehaviorSubject<boolean>(false);
  private logoutFlag = new BehaviorSubject<boolean>(false);
  private acceptOrRecuseTermsValue = new BehaviorSubject<boolean>(false);
  private fixedNavBar = new BehaviorSubject<boolean>(false);
  private passTest = new BehaviorSubject<boolean>(false);
  private cookiePixelFlag = new BehaviorSubject<boolean>(false);
  private urlRedirect = new BehaviorSubject<object>({ url: '', flag: false });

  public showNavBar: BehaviorSubject<any> = new BehaviorSubject(this.getNavBarSemLogin());
  public chatSupport = true;

  private assinantes = [];

  //#region flags para buscar uma vez no back-end
  private tipoBusca = '';
  private sinalService = true;
  private filtroDropboxService = true;
  private diagramaService = true;
  private emitService = true;
  //#endregion

  LOG = false;
  emitirNotifcation = new EventEmitter<string>();

  constructor(
    private _http: HttpClient,
    private route: Router
  ) {
  }


  getChatSupporte() {
    return this.chatSupport;
  }

  attChatSupporte(flag) {
    this.chatSupport = flag
  }

  getNavBarComLogin() {
    return {
      inicial: false,
      planos: true,
      ranking: false,
      downloads: true,
      blog: true,
      cursos: false,
    }
  }

  getNavBarSemLogin() {
    return {
      inicial: true,
      planos: true,
      ranking: true,
      downloads: false,
      blog: true,
      cursos: false
    }
  }

  getTipoBusca() {
    return this.tipoBusca;
  }

  setTipoBusca(service) {
    this.tipoBusca = service;
  }

  getEmit() {
    return this.emitService;
  }

  setEmit(service) {
    this.emitService = service;
  }

  getDiagramaService() {
    return this.diagramaService;
  }

  setDiagramaService(service) {
    this.diagramaService = service;
  }

  getSinalService() {
    return this.sinalService;
  }

  setSinalService(service) {
    this.sinalService = service;
  }

  getFiltroDropboxService() {
    return this.filtroDropboxService;
  }

  setFiltroDropboxService(service) {
    this.filtroDropboxService = service;
  }

  emit(user: any, token: any, acesskey: any, from?: any) {
    if (user == null || user == undefined || user == '' || token == null || token == undefined || token == '') {
      this.logoutUser();
    } else {
      if (user) {
        if (user.crudPermission.admin == true) {
          localStorage.setItem('admin', 'true')
        }
      }
      localStorage.setItem('token', token)
      this.user.next(user);
      this.token.next(token);
      if (user.auth.email == 'visitante@ind.br') {
        localStorage.setItem('fake-user', 'true');
        this.setFakeLogin(true);
        localStorage.removeItem('acesskey');
      } else {
        localStorage.setItem('acesskey', acesskey);
        this.setFakeLogin(false);
        localStorage.removeItem('fake-user');
      }
    }

    if (acesskey == null || acesskey == undefined) {
      localStorage.removeItem('acesskey');
    }
  }

  logoutUser() {
    localStorage.clear();
    sessionStorage.clear();
    this.token.next(null);
    this.user.next(null);
    this.setFakeLogin(true);
    this.setLogoutFlag(true);
    this.setLoginUser(false);
    this.route.navigate([`${environment.url_version}/user/login`]);
  }

  emitShowNavBar(nav_bar, from?) {
    this.showNavBar.next(nav_bar);
  }

  getUser() {
    return this.user;
  }

  setUser(user) {
    this.user.next(user);
  }

  getToken() {
    return this.token;
  }

  getData() {
    return this._http.get(`${environment.api_server}/api/module/public/page/index`);
  }

  getEmailUsers() {
    return this._http.get(`${environment.api_server}/api/module/user/signature/test/email`,
      { headers: SharedService.header }).pipe(take(1));
  }

  getAssinantes() {
    return this.assinantes;
  }

  setAssinantes(assinantes) {
    this.assinantes = assinantes;
  }

  notificationClick(id) {
    return this._http.put(`${environment.api_server}/api/module/notification/${id}/visualized`, {}, { headers: SharedService.header })
  }

  authenticationByToken(token, diagramaId) {
    return this._http.post(environment.api_server + '/api/module/user/login/diagrama/token', { diagramaId: diagramaId }, {
      headers: new HttpHeaders()
        .append('Authorization', 'Bearer ' + token)
    }).pipe(
      take(1), // desescrever automático após 1 tentativa
      // tap(console.log)
    );
  }

  getDiagramById(id) {
    return this._http.post(`${environment.api_server}/api/diagrama/pesquisa/id`, { id: id }, { headers: SharedService.header })
  }

  getVersionSoftware(arquivo: any) {
    return this._http.post(`${environment.api_server}/api/newtecnoscopio/software/download`, { arquivo: arquivo },
      { headers: SharedService.header, responseType: 'arraybuffer', reportProgress: true, observe: "events" })
  }

  acceptOrRecuseTerms(valor: boolean) {
    this.acceptOrRecuseTermsValue.next(valor);
  }

  sendAcceptOrRecuseTerms() {
    return this.acceptOrRecuseTermsValue;
  }

  emitCurvedLinks(valor) {
    this.acceptOrRecuseTermsValue.next(valor);
  }

  returnCurvedLinks() {
    return this.acceptOrRecuseTermsValue;
  }

  acceptOrRecuseAlert(valor: boolean) {
    this.acceptOrRecuseAlertValue.next(valor);
  }

  sendAcceptOrRecuseAlert() {
    return this.acceptOrRecuseAlertValue;
  }

  userFromServer(token) {
    return this._http.get(`${environment.api_server}/api/module/user/id`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + token) });
  }

  loginFromToken(token) {
    return this._http.get(`${environment.api_server}/api/module/user/id`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + token) });
  }

  // getNotification() {
  //   return this._http.get(`${environment.api_server}/api/module/forum/notification/get`, { headers: SharedService.header });
  // }

  async checkPermission(userPermission, itemPermission) {
    console.log('Verificando permissão', userPermission, itemPermission)
    let returnCheck = false;
    await userPermission.forEach(permission => {
      if (permission === itemPermission) {
        returnCheck = true;
      }
    });
    console.log('Resultado permissão', returnCheck)
    return returnCheck;
  }

  sendUrlRedirect(valor: object) {
    this.urlRedirect.next(valor);
  }

  getUrlRedirect() {
    return this.urlRedirect;
  }

  setFakeLogin(valor: boolean) {
    this.fakeLogin.next(valor);
  }

  getFakeLogin() {
    return this.fakeLogin.asObservable();
  }

  getFakeLoginValue() {
    return this.fakeLogin;
  }

  setMobile(valor: string) {
    this.mobileFlag.next(valor);
  }

  getMobile() {
    return this.mobileFlag.asObservable();
  }

  setMobileApp(valor: boolean) {
    this.mobileFlagApp.next(valor);
  }

  getMobileApp() {
    return this.mobileFlagApp.asObservable();
  }

  setSoftwareNew(valor: boolean) {
    this.softwareNew.next(valor);
  }

  getSoftwareNew() {
    return this.softwareNew.asObservable();
  }

  setMobileAppNew(valor: boolean) {
    this.mobileFlagAppNew.next(valor);
  }

  getMobileAppNew() {
    return this.mobileFlagAppNew.asObservable();
  }

  setLogoutFlag(valor: boolean) {
    this.logoutFlag.next(valor);
  }

  getLogoutFlag() {
    return this.logoutFlag.asObservable();
  }

  setDownloadModal(valor: boolean) {
    this.modalDownload.next(valor);
  }

  getDownloadModal() {
    return this.modalDownload.asObservable();
  }

  setDownloadProgress(valor: number) {
    this.downloadProgress.next(valor);
  }

  getDownloadProgress() {
    return this.downloadProgress.asObservable();
  }

  getCEP(cep) {
    return this._http.post(`${environment.api_server}/api/module/user/address`, { cep: cep })
  }


  getPassSiteTeste() {
    return this.passTest;
  }

  emitPassSiteTeste(valor) {
    this.passTest.next(valor);
  }

  setLoginUser(valor: boolean) {
    this.loginUser.next(valor);
  }

  getLoginUser() {
    return this.loginUser.asObservable();
  }

  loginByToken(token) {
    return this._http.get(`${environment.api_server}/api/module/software/user/login/token`, { headers: SharedService.headerWithToken(token) });
  }

  setFixedNavBar(valor) {
    this.fixedNavBar.next(valor);
  }

  getFixedNavBar() {
    return this.fixedNavBar;
  }

  // Função para obter as versões dos softwares
  getSoftwareVersions(): Observable<HttpResponse<any>> {
    return this._http.get<HttpResponse<any>>(`${environment.api_server}/api/newtecnoscopio/software/versions`, { headers: SharedService.header, observe: 'response' });
  }

  // Função para solicitar o link de download dos arquivos
  getDownloadLink(fileName: any): Observable<HttpResponse<any>> {
    return this._http.post<HttpResponse<any>>(`${environment.api_server}/api/newtecnoscopio/software/download`, { arquivo: fileName }, { headers: SharedService.header, observe: 'response' });
  }

}
