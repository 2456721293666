import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { SharedService } from '../../shared.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-serial',
  templateUrl: './serial.component.html',
  styleUrls: ['./serial.component.scss']
})
export class SerialComponent implements OnInit {
  formSerial = new FormControl('', [
    Validators.required,
    this.minLength
  ]);

  isFocused = false;
  serialRepetido = '';
  serialRepetidoActive = false;

  public maskSerial = [/[0-9a-zA-Z]/, /[0-9a-zA-Z]/, /[0-9a-zA-Z]/, '-',
    /[0-9a-zA-Z]/, /[0-9a-zA-Z]/, /[0-9a-zA-Z]/,
    '-', /[0-9a-zA-Z]/, /[0-9a-zA-Z]/, /[0-9a-zA-Z]/,
    '-', /[0-9a-zA-Z]/, /[0-9a-zA-Z]/, /[0-9a-zA-Z]/,
    /[0-9a-zA-Z]/];

  private m_encodeMap = {
    "00000": "A",
    "00001": "B",
    "00010": "C",
    "00011": "D",
    "00100": "E",
    "00101": "F",
    "00110": "G",
    "00111": "H",
    "01000": "I",
    "01001": "J",
    "01010": "K",
    "01011": "L",
    "01100": "M",
    "01101": "N",
    "01110": "O",
    "01111": "P",
    "10000": "Q",
    "10001": "R",
    "10010": "S",
    "10011": "T",
    "10100": "U",
    "10101": "V",
    "10110": "W",
    "10111": "X",
    "11000": "Y",
    "11001": "Z",
    "11010": "9",
    "11011": "1",
    "11100": "7",
    "11101": "6",
    "11110": "3",
    "11111": "4"
  }

  constructor(
    public dialogRef: MatDialogRef<SerialComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data) {
      this.formSerial.patchValue(this.data);
    }
    this.serialRepetidoActive = false;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  showError(): boolean {
    return (this.formSerial.invalid && this.formSerial.touched) || this.serialRepetidoActive;
  }

  serialText2Number(serial): number {
    if (!serial) return 0;

    serial = serial.replace(/-/g, "");
    let binario = '';

    if (serial.includes('2') || serial.includes('5') || serial.includes('8')) {
      return 0;
    }

    for (let i = 0; i < serial.length; i++) {
      Object.keys(this.m_encodeMap).forEach((key) => {
        if (serial[i] === this.m_encodeMap[key]) {
          binario = `${binario}${key}`;
        }
      });
    }

    if (binario[binario.length - 1] === '0') {
      return 0;
    }

    binario = binario.substring(0, binario.length - 1);
    return 1;
  }

  onSaveClick(): void {
    if (!this.formSerial.value) {
      this.formSerial.markAsTouched();
      return;
    }

    if (this.serialText2Number(this.formSerial.value) === 0) {
      this.serialRepetido = 'Serial inválido!';
      this.serialRepetidoActive = true;
      return;
    }

    this.serialRepetidoActive = false;
    this.dialogRef.close(this.formSerial.value.toUpperCase());
  }

  removeAlert(): void {
    this.serialRepetidoActive = false;
  }

  minLength(c: FormControl, min: number = 13): any {
    if (!c.value) {
      return null;
    }

    const s = c.value.replace(/[^a-z0-9]/gi, '');

    if (s.length < min) {
      return { minLength: true };
    }

    return null;
  }
}