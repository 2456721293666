import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() pager: any = {
    currentPage: 1,
    totalPages: 1,
    pages: [1]
  };
  @Input() service: string = 'Desktop';
  @Output() pageChanged = new EventEmitter<number>();

  setPage(page: number): void {
    if (page < 1 || (this.pager.totalPages && page > this.pager.totalPages)) {
      return;
    }
    this.pageChanged.emit(page);
  }
}