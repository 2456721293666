import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ErrorState } from '../../../forms/error-state';
import { Controls } from '../../../forms/controls';
import { FormControl, Validators } from '@angular/forms';
import { ForgotPasswordService } from './forgot-password.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  matcher = new ErrorState();
  controls = new Controls();
  formEmailControl: FormControl;
  debounceTimeout: any = null;
  isValidEmail: boolean = false;
  isSearching: boolean = false;
  searchEmail: string = '';
  isFocused: boolean = false;

  constructor(
    private dialog: MatDialogRef<ForgotPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private forgotPasswordService: ForgotPasswordService
  ) {
    this.formEmailControl = new FormControl('', [Validators.required, Validators.email]);
  }

  ngOnInit() {
    if (!this.forgotPasswordService.emailStore) {
      this.forgotPasswordService.emailStore = '';
    }
    this.searchEmail = this.forgotPasswordService.emailStore;
    this.formEmailControl.patchValue(this.forgotPasswordService.emailStore);
    this.controls.setService(this.forgotPasswordService);

    if (this.searchEmail && this.formEmailControl.valid) {
      this.validateEmail(this.searchEmail);
    }
  }

  onEmailInputChange(email: string) {
    this.searchEmail = email;
    this.isValidEmail = false;

    if (email && this.formEmailControl.valid) {
      this.isSearching = true;

      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.validateEmail(email);
      }, 800);
    } else {
      this.isSearching = false;
    }
  }

  validateEmail(email: string) {
    if (!this.formEmailControl.valid) {
      this.isSearching = false;
      return;
    }

    this.isSearching = true;
    this.forgotPasswordService.checkEmail(email).pipe(take(1)).subscribe(
      (res: any) => {
        this.isValidEmail = res.code === 200;
        this.isSearching = false;
      },
      () => {
        this.isValidEmail = false;
        this.isSearching = false;
      }
    );
  }

  cancel() {
    this.dialog.close();
  }

  confirm() {
    if (this.isValidEmail && this.formEmailControl.valid) {
      this.forgotPasswordService.emailStore = this.formEmailControl.value;
      this.dialog.close(this.formEmailControl.value);
    }
  }
}