import { ModalService } from './../../shared/services/modal.service';
import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-new-nav-bar',
  templateUrl: './new-nav-bar.component.html',
  styleUrls: ['./new-nav-bar.component.scss']
})

export class NewNavBarComponent implements OnInit, OnDestroy {

  user;
  fakeUser: boolean = false;
  mobileFlagAppNew: boolean = false;
  mobileFlag: boolean = false;
  modalDownload: boolean = false;
  downloadProgress: number = 0;
  token: string;
  softwareLogin: string;
  service = "Desktop"
  notifications = [];
  userName: string;
  isSmallScreen = false;
  isMenuOpen: boolean = false;

  routerLink_URL = environment.url_version == '' ? `${environment.url_version}` : `/${environment.url_version}`;
  routerLink_URL_sobre = `${this.routerLink_URL}/sobre`;
  routerLink_URL_perfilDownloads = `${this.routerLink_URL}/perfil/downloads`;

  showNavbar;

  private resizeListener: () => void;
  private clickListener: (event: MouseEvent) => void;

  constructor(
    private _route: Router,
    private appService: AppService,
    private modalService: ModalService,
    private ativatedRoute: ActivatedRoute,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.initThemeSwitcher();
    this.appService.getFakeLogin().subscribe(flag => {
      this.fakeUser = flag;
    });

    this.appService.getMobileApp().subscribe(flag => {
      this.mobileFlag = flag;
    });

    this.appService.getDownloadModal().subscribe(flag => {
      this.modalDownload = flag;
    });

    this.appService.getDownloadProgress().subscribe(flag => {
      this.downloadProgress = flag;
    });

    this.appService.getMobileAppNew().subscribe(flag => {
      this.mobileFlagAppNew = flag;
    });

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        navigator.userAgent
      )
    )
      this.service = "Mobile";
    else this.service = "Desktop";
    this.softwareLogin = sessionStorage.getItem('software-new');
    this.user = this.appService.getUser().value;

    this.appService.user.subscribe((user) => {
      if (user) {
        this.user = user;
        console.log(this.user);
        this.appService.emitShowNavBar(this.appService.getNavBarComLogin());
        this.showNavbar = this.appService.getNavBarComLogin();
      } else {
        this.appService.emitShowNavBar(this.appService.getNavBarSemLogin());
        this.showNavbar = this.appService.getNavBarSemLogin();
      }
    });

    this.ativatedRoute.data.subscribe((info) => {
      if (info.user) this.user = info.user;
    });

    this.appService.showNavBar.subscribe((nav_bar) => {
      this.showNavbar = nav_bar;
    });

    this.checkThemeStatus();
    this.setupEventListeners();
    this.checkScreenSize();
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.resizeListener);

    if (this.clickListener) {
      document.removeEventListener('click', this.clickListener as EventListener);
    }
  }

  setupEventListeners() {
    this.resizeListener = () => {
      this.checkScreenSize();
    };
    window.addEventListener('resize', this.resizeListener);

    this.clickListener = (event: any) => {
      const navbar = document.querySelector('.navbar-container');
      const target = event.target as HTMLElement;

      if (this.isMenuOpen && navbar && !navbar.contains(target)) {
        this.closeMenu();
      }
    };
    document.addEventListener('click', this.clickListener);
  }

  checkScreenSize() {
    this.isSmallScreen = window.innerWidth <= 500;

    if (window.innerWidth > 992) {
      this.closeMenu();
    }
  }

  ngAfterViewChecked() {
    if (this.user == null || this.user == undefined || !this.user) {
      return
    }
  }

  getFormatedName(user) {
    try {
      let Fullname = this.user.personalData.name;
      const formataNome = str => {
        return str.toLowerCase().replace(/(?:^|\s)(?!da|de|do)\S/g, l => l.toUpperCase());
      };
      let parts = Fullname.split(' ');
      return `${parts[0]}`;

    } catch (e) {
      this.appService.emit(undefined, undefined, undefined);
      this._route.navigate([`${environment.url_version}/user/login`]);
    }
  }

  isActive() {
    let retorno;

    if (this._route.url.indexOf('blog-post') !== 0)
      retorno = true;

    if (this._route.url.indexOf('blog') !== 0)
      retorno = true;

    if (this._route.url.indexOf('blog') === -1)
      retorno = false;

    return retorno
  }

  confirmLogout() {
    this.modalService.openModal(
      'Confirmar saída',
      'Tem certeza que deseja sair?',
      'warning',
      'Sim',
      'Cancelar'
    ).subscribe(result => {
      if (result) {
        this.appService.logoutUser();
      }
    });
  }

  closeModalDownload() {
    this.appService.setDownloadModal(false);
  }

  closeWebView() {
    const url = `${environment.url_version}/close-browser`;
    window.location.href = url;
  }

  toggleMobileMenu(event?: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const navContent = document.getElementById('mainNavContent');
    const toggler = document.querySelector('.navbar-toggler');

    this.isMenuOpen = !this.isMenuOpen;

    if (navContent) {
      if (this.isMenuOpen) {
        this.renderer.addClass(navContent, 'show');
      } else {
        this.renderer.removeClass(navContent, 'show');
      }
    }

    if (toggler) {
      if (this.isMenuOpen) {
        this.renderer.addClass(toggler, 'active');
      } else {
        this.renderer.removeClass(toggler, 'active');
      }
    }

    if (event) {
      event.stopPropagation();
    }
  }

  closeMenu() {
    if (!this.isMenuOpen) return;

    this.isMenuOpen = false;

    const navContent = document.getElementById('mainNavContent');
    const toggler = document.querySelector('.navbar-toggler');

    if (navContent) {
      this.renderer.removeClass(navContent, 'show');
    }

    if (toggler) {
      this.renderer.removeClass(toggler, 'active');
    }
  }

  initThemeSwitcher() {
    setTimeout(() => {
      const themeSwitcher = document.getElementById('themeSwitcher');
      const body = document.body;
      const savedTheme = localStorage.getItem('theme');

      if (savedTheme === 'light') {
        body.classList.add('light-mode');
        document.documentElement.classList.add('light-mode');
      }

      if (themeSwitcher) {
        themeSwitcher.addEventListener('click', () => {
          body.classList.toggle('light-mode');
          document.documentElement.classList.toggle('light-mode');
          if (body.classList.contains('light-mode')) {
            localStorage.setItem('theme', 'light');
          } else {
            localStorage.setItem('theme', 'dark');
          }
        });
      }
    }, 1);
  }

  checkThemeStatus() {
    setInterval(() => {
      const isLightMode = document.body.classList.contains('light-mode');
      const sunIcon: any = document.querySelector('.sun-icon');
      const moonIcon: any = document.querySelector('.moon-icon');

      if (isLightMode) {
        if (sunIcon) sunIcon.style.opacity = '1';
        if (sunIcon) sunIcon.style.transform = 'translateY(0)';
        if (moonIcon) moonIcon.style.opacity = '0';
        if (moonIcon) moonIcon.style.transform = 'translateY(-20px)';
      } else {
        if (sunIcon) sunIcon.style.opacity = '0';
        if (sunIcon) sunIcon.style.transform = 'translateY(20px)';
        if (moonIcon) moonIcon.style.opacity = '1';
        if (moonIcon) moonIcon.style.transform = 'translateY(0)';
      }
    }, 200);
  }

  checkUserFake() {
    if (!this.user || !this.user._id) {
      return true;
    }
    return this.user._id == "5f71f7d333300b5662abaede";
  }
}