import { environment } from './../../../environments/environment';
import { ModalService } from './modal.service';
import { AppService } from './../../app.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SignalDownloadService {

  constructor(
    private appService: AppService,
    private modalService: ModalService,
    private router: Router
  ) { }

  async hasPermission(item: any): Promise<boolean> {
    const user = await this.appService.getUser().value;
    if (user && user.permissao) {
      return user.permissao.indexOf(item.permissaoId) > -1;
    }
    return true;
  }

  redirectToPlans() {
    this.router.navigate([`${environment.url_version}/planos`]);
  }

  initiateSignalDownload(signal) {
    if (!this.hasPermission(signal)) {
      this.redirectToPlans();
      return;
    }
    if (sessionStorage.getItem('software-new') !== 'true') {
      this.modalService.openModal(
        'Aviso!',
        'Para fazer downloads de sinais é necessário abrir o site pelo software new tecnoscópio!!',
        'warning',
        'Continuar',
        'Cancelar'
      ).subscribe((res) => {
        if (res) {
          this.appService.acceptOrRecuseAlert(true);
          this.prepareSignalDataForDownload(signal);
        } else {
          this.appService.acceptOrRecuseAlert(false);
        }
      });
    } else {
      this.appService.acceptOrRecuseAlert(true);
      this.prepareSignalDataForDownload(signal);
    }
  }

  prepareSignalDataForDownload(signal) {
    const acceptRecuse = this.appService.sendAcceptOrRecuseAlert();

    if (acceptRecuse.value) {
      this.extractAndFormatSignalData(signal);
    }
  }

  extractAndFormatSignalData(signal) {
    const relevantProperties = [
      "brand", "model", "module", "motor", "name", "sett",
      "year", "injSystem", "_id", "signalType", "sinalValidadoId"
    ];

    const filteredSignalData = {};
    relevantProperties.forEach(property => {
      if (signal.hasOwnProperty(property)) {
        filteredSignalData[property] = signal[property];
      }
    });

    const formattedSignalData = JSON.stringify(filteredSignalData);
    console.log(formattedSignalData);

    return formattedSignalData;
  }

}
