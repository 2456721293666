import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SignalService } from '../../services/signal.service';

@Component({
  selector: 'app-signal-details-modal',
  templateUrl: './signal-details-modal.component.html',
  styleUrls: ['./signal-details-modal.component.scss']
})
export class SignalDetailsModalComponent implements OnInit {
  signal: any;
  user: any;
  service: string = 'Desktop';
  loadingImg: boolean = false;
  showImageModal: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<SignalDetailsModalComponent>,
    private signalService: SignalService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.signal = this.data.signal;
    this.user = this.data.user;
    this.service = this.data.service || 'Desktop';
  }

  close(): void {
    this.dialogRef.close();
  }

  getSignalImage(): string {
    if (this.signal && this.signal.photo) {
      return 'data:image/jpg;base64,' + this.signal.photo;
    }
    return '';
  }

  openImageModal(): void {
    this.showImageModal = true;
  }

  closeImageModal(): void {
    this.showImageModal = false;
  }

  isAdmin(): boolean {
    return (this.user && this.user.crudPermission && this.user.crudPermission.admin) ||
      (this.user && this.user.crudPermission && this.user.crudPermission.moderador);
  }

  canDownload(): boolean {
    return this.user && this.user.permissao && this.signal && this.signal.permissaoId &&
      this.user.permissao.indexOf(this.signal.permissaoId) > -1;
  }

  downloadSignal(signal: any): void {
    this.dialogRef.close({ action: 'download', signal: signal });
  }

  getFormattedName(name: string): string {
    if (!name) return '';
    return name.split(' ').map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()).join(' ');
  }
}