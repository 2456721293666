import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { FormularioService } from './../../../shared/formulario/formulario.service';
import { Controls } from './../../../shared/forms/controls';
import { ModalService } from './../../../shared/services/modal.service';
import { EmailSuggestionService } from './../../../shared/services/email-suggestion.service';
import { UserAuthenticationService } from '../user-authentication.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterComponent implements OnInit {

  personalData: FormGroup;
  auth: FormGroup;
  controls: any = new Controls();
  isCnpj = false;
  userIsForeigner = false;
  whatsapp = false;
  personType = 'start';
  errorPerson = null;
  timeout: any = null;
  foreignerInput = '';
  cpfInput = '';
  cnpjInput = '';
  cpfInputCallCount = 0;
  whatsappChecked = false;
  isCheckboxChecked = false;
  onCapsLock = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private formService: FormularioService,
    private modalService: ModalService,
    private emailSuggestionService: EmailSuggestionService,
    private userAuthenticationService: UserAuthenticationService,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2
  ) {
    this.controls.setService(this.formService);
    this.initializeForms();
  }

  ngOnInit() {
    this.userIsForeigner = this.checkIfForeigner();

    if (window.location.href.split("?")[1] == "foreigner=" || window.location.href.split("?")[1] == "foreigner") {
      this.userIsForeigner = true;
    }

    this.setupEmailSuggestion();
    this.checkWhatsAppValidity();
    this.setupCapsLockDetection();
  }

  initializeForms() {
    this.personalData = this.fb.group({
      name: [null, [Validators.required, Validators.minLength(8)]],
      isCPF: 'cpf',
      whatsapp: false,
      cel: [null, [Validators.required, this.validPhoneNumberValidator()]],
      person: [null, [Validators.required, this.validCPFAndCNPJValidator()]],
      foreigner: [null],
    });

    this.auth = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      confirmEmail: [null, [Validators.required, this.matchEmailValidator('email')]],
      passwordTemp: [null, [Validators.required, Validators.minLength(8)]],
      password: [null, [Validators.required, Validators.minLength(8), this.matchPasswordValidator()]]
    });

    this.auth.get('passwordTemp').valueChanges.subscribe(() => {
      this.auth.get('password').updateValueAndValidity();
    });

    if (this.userIsForeigner) {
      this.controls.maskCelForeigner = ['+', /\d/, /\d*/, /\d*/, /\d*/, /\d*/, /\d*/, /\d*/, /\d*/, /\d*/, /\d*/, /\d*/, /\d*/, /\d*/, /\d*/, /\d*/];
    }
  }

  setupEmailSuggestion() {
    let emailSuggestionShown = false;
    let emailSuggestionRejected = false;

    this.auth.get('email').valueChanges.subscribe(() => {
      this.auth.get('confirmEmail').updateValueAndValidity();
      const email = this.auth.get('email').value;

      if (emailSuggestionRejected || !email) {
        return;
      }

      const suggestion = this.emailSuggestionService.suggestDomainCorrection(email);

      if (suggestion && !emailSuggestionShown) {
        emailSuggestionShown = true;
        this.modalService.openModal('Sugestão', `Você quis dizer: ${suggestion}?`, 'warning', 'Sim', 'Não')
          .subscribe((callback) => {
            if (callback) {
              this.auth.get('email').setValue(suggestion);
            } else {
              emailSuggestionRejected = true;
            }
            emailSuggestionShown = false;
          });
      }
    });
  }

  setupCapsLockDetection() {
    document.addEventListener('keyup', (e) => {
      try {
        if (e.getModifierState('CapsLock')) {
          this.onCapsLock = true;
          this.cdRef.detectChanges();
        } else {
          this.onCapsLock = false;
          this.cdRef.detectChanges();
        }
      } catch (error) {

      }
    });
  }

  checkIfForeigner(): boolean {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const brazilianTimeZones = [
      'America/Sao_Paulo',
      'America/Rio_Branco',
      'America/Manaus',
      'America/Cuiaba',
      'America/Boa_Vista',
      'America/Porto_Velho',
      'America/Recife',
      'America/Bahia',
      'America/Fortaleza',
      'America/Maceio',
      'America/Araguaina',
    ];
    return !brazilianTimeZones.some(timeZone => userTimeZone.startsWith(timeZone));
  }

  checkWhatsApp() {
    this.whatsappChecked = true;
    this.modalService.openModal('Confirmação WhatsApp', 'Esse número é WhatsApp?', 'warning', 'Sim', 'Não')
      .subscribe((callback) => {
        this.whatsapp = callback;
        this.personalData.get('whatsapp').setValue(this.whatsapp);
        this.cdRef.detectChanges();
      });
  }

  checkWhatsAppValidity() {
    this.personalData.get('cel').valueChanges.subscribe(value => {
      const validPhonePattern = /^\(\d{2}\) \d{5}-\d{4}$/;
      if (value && value.match(validPhonePattern)) {
        if (!this.whatsappChecked) {
          this.checkWhatsApp();
        }
      } else {
        this.whatsappChecked = false;
      }
    });
  }

  saveRegister() {
    if (this.auth.invalid || (this.userIsForeigner && this.personalData.get('foreigner').invalid) ||
      (!this.userIsForeigner && this.personalData.get('person').invalid) ||
      this.personalData.get('name').invalid ||
      this.personalData.get('cel').invalid) {
      this.markAllFieldsAsTouched();
      return;
    }

    if (this.auth.get('passwordTemp').value !== this.auth.get('password').value) {
      this.auth.get('password').setErrors({ notEqual: true });
      return;
    }

    if (!this.isCheckboxChecked) {
      this.modalService.openModal(
        'Aviso',
        'Por favor, marque a caixa para aceitar a Política de Privacidade antes de prosseguir.',
        'warning',
        'Entendi'
      ).subscribe();
      return;
    }

    this.modalService.openModal('Confirmar Registro', 'Deseja realmente criar sua conta?', 'warning', 'Sim', 'Não')
      .subscribe((callback) => {
        if (!callback) return;

        const personalDataCopy = { ...this.personalData.value };

        if (this.userIsForeigner) {
          personalDataCopy.estrangeiro = personalDataCopy.foreigner;
          delete personalDataCopy.person;
        } else {
          if (!this.isCnpj) {
            personalDataCopy.cpf = personalDataCopy.person.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
          } else {
            personalDataCopy.cnpj = personalDataCopy.person.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
          }
          delete personalDataCopy.foreigner;
        }

        personalDataCopy.whatsapp = this.whatsapp;

        const userFormData = {
          personalData: personalDataCopy,
          auth: { ...this.auth.value }
        };

        delete userFormData.auth.confirmEmail;
        delete userFormData.auth.passwordTemp;

        try {
          this.userAuthenticationService.createUserAccount(userFormData).subscribe((res: any) => {
            if (res.code == 200) {
              this.modalService.openModal('Sucesso', 'Sua conta foi criada. Verifique o email recebido e siga as orientações.', 'success', 'Ok')
                .subscribe(() => {
                  this.router.navigate([`osciloscopio/user/login`]);
                });
            } else {
              this.modalService.openModal('Falha!', res.msg || 'Algo deu errado. Por favor, tente novamente.', 'error', 'Ok');
            }
          }, (error) => {
            this.modalService.openModal('Falha!', error.error.msg || 'Algo deu errado. Por favor, tente novamente.', 'error', 'Ok');
          });
        } catch (error) {
          this.modalService.openModal('Falha!', 'Não foi possível processar sua solicitação!', 'error', 'Ok');
        }
      });
  }

  checkInputsFormRegister(): boolean {
    let fields = [
      { value: this.personalData.value.name, errorMessage: 'Por favor, insira seu nome.' },
      { value: this.personalData.value.cel, errorMessage: 'Por favor, insira seu número de celular.' },
      { value: this.auth.value.email, errorMessage: 'Por favor, insira seu e-mail.' },
      { value: this.auth.value.confirmEmail, errorMessage: 'Por favor, confirme seu e-mail.' },
      { value: this.auth.value.passwordTemp, errorMessage: 'Por favor, insira sua senha.' },
      { value: this.auth.value.password, errorMessage: 'Por favor, insira sua senha.' },
    ];

    if (this.userIsForeigner) {
      fields.push({ value: this.personalData.value.foreigner, errorMessage: 'Por favor, insira seu Documento.' });
    } else {
      fields.push({ value: this.personalData.value.person, errorMessage: 'Por favor, insira seu CPF.' });
    }

    for (const field of fields) {
      if (!field.value) {
        return false;
      }
    }
    return true;
  }

  markAllFieldsAsTouched() {
    Object.keys(this.personalData.controls).forEach((key) => {
      this.personalData.get(key).markAsTouched();
    });
    Object.keys(this.auth.controls).forEach((key) => {
      this.auth.get(key).markAsTouched();
    });
    this.cdRef.detectChanges();
  }

  onKeySearch(event: any) {
    const input = event.target.value.replace(/\D/g, '');
    this.personalData.patchValue({ person: input }, { emitEvent: false });

    this.personType = 'start';
    this.errorPerson = null;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      switch (input.length) {
        case 11:
          this.isCnpj = false;
          this.personType = 'cpf';
          this.applyCpfMask(input);
          break;

        case 14:
          this.isCnpj = true;
          this.personType = 'cnpj';
          this.applyCnpjMask(input);
          break;

        default:
          this.personType = 'start';
          this.errorPerson = 'A numeração não corresponde a um CPF ou CNPJ.';
          break;
      }
      this.cdRef.detectChanges();
    }, 500);
  }

  applyCpfMask(cpfDigits: string) {
    const maskedCpf = cpfDigits.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    this.personalData.patchValue({ person: maskedCpf });
  }

  applyCnpjMask(cnpjDigits: string) {
    const maskedCnpj = cnpjDigits.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    this.personalData.patchValue({ person: maskedCnpj });
    this.simulateUserInteraction('cnpjInputRef');
  }

  simulateUserInteraction(inputId: string) {
    setTimeout(() => {
      const inputElement = document.getElementById(inputId);
      if (inputElement) {
        this.renderer.selectRootElement(inputElement).focus();
      }
    }, 0);
  }

  focusOutPerson() {
    this.onKeySearch({ target: { value: this.personalData.get('person').value } });
  }

  focusPerson() {
    this.personType = 'start';
    this.errorPerson = null;
    setTimeout(() => {
      const element = document.getElementById('cpfInputRef');
      if (element) {
        element.focus();
      }
    }, 5);
  }

  removeSpaces(form: string, field: string) {
    if (this[form] && this[form].controls[field]) {
      const fieldValue = this[form].controls[field].value;
      if (fieldValue) {
        const fieldValueTrimmed = fieldValue.trim();
        this[form].patchValue({ [field]: fieldValueTrimmed });
      }
    }
  }

  matchEmailValidator(emailControlName: string) {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!this.auth) return null;
      const emailControl = this.auth.get(emailControlName);
      if (emailControl && control.value !== emailControl.value) {
        return { match: true };
      }
      return null;
    };
  }

  disableCopy(event: ClipboardEvent) {
    event.preventDefault();
  }

  disablePaste(event: ClipboardEvent) {
    event.preventDefault();
  }

  matchPasswordValidator() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!this.auth) return null;
      const passwordTempControl = this.auth.get('passwordTemp');
      if (passwordTempControl && control.value !== passwordTempControl.value) {
        return { notEqual: true };
      }
      return null;
    };
  }

  validPhoneNumberValidator() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value;

      if (this.userIsForeigner) {
        const validPhonePatternForeigner = /^\+\d{5,}$/;
        if (value && !validPhonePatternForeigner.test(value)) {
          return { invalid: true };
        }
      } else {
        const validPhonePattern = /^\(\d{2}\) \d{5}-\d{4}$/;
        if (value && !validPhonePattern.test(value)) {
          return { invalid: true };
        }
      }

      return null;
    };
  }

  validCPFAndCNPJValidator() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const validCpfPattern = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
      const validCnpjPattern = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
      if (control.value && !(control.value.match(validCpfPattern) || control.value.match(validCnpjPattern))) {
        return { invalid: true };
      }
      return null;
    };
  }

  toggleCheckbox(event: any) {
    this.isCheckboxChecked = event.target.checked;
  }
}