import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SignalService } from '../../services/signal.service';
import { NoticeComponent } from '../../alerts/notice/notice.component';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-delete-signal-notification',
  templateUrl: './delete-signal-notification.component.html',
  styleUrls: ['./delete-signal-notification.component.scss']
})
export class DeleteSignalNotificationComponent implements OnInit {
  deleteForm = new FormGroup({
    reason: new FormControl('', [Validators.required])
  });

  showError = false;
  isFocused = false;

  constructor(
    private signalService: SignalService,
    private dialog: MatDialog,
    private modalService: ModalService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.deleteForm.get('reason').valueChanges.subscribe(() => {
      if (this.showError && this.deleteForm.get('reason').valid) {
        this.showError = false;
      }
    });
  }

  isFormValid(): boolean {
    return this.deleteForm.valid;
  }

  cancel(): void {
    this.dialog.closeAll();
  }

  async confirm(signalId: string): Promise<void> {
    try {
      this.signalService.notificarExclusaoSinal(signalId, { resposta: this.deleteForm.get('reason').value }).subscribe((response: any) => {
        this.dialog.closeAll();
        if (response.status === 200) {
          this.modalService.openModal('Sucesso', response.body.msg, 'success', 'Ok').subscribe();
        } else {
          this.modalService.openModal('Falha!', response.body.msg || 'Algo deu errado. Por favor, tente novamente.', 'error', 'Ok').subscribe(() => { });
        }
      }, (error: any) => {
        this.dialog.closeAll();
        this.modalService.openModal('Falha!', error.error.msg || 'Algo deu errado. Por favor, tente novamente.', 'error', 'Ok').subscribe(() => { });
      });
    } catch (error) {
      this.dialog.closeAll();
      this.modalService.openModal('Falha!', 'Ocorreu um erro.', 'error', 'Ok').subscribe(() => { });
    }
  }
}