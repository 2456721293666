import { DeleteSignalNotificationComponent } from './../../../shared/components/delete-signal-notification/delete-signal-notification.component';
import { LoginToContinueComponent } from './../../../shared/alerts/login-to-continue/login-to-continue.component';
import { InfoAtrelamentoComponent } from './../../../shared/alerts/info-atrelamento/info-atrelamento.component';
import { Component, OnInit, Input, Renderer2, ElementRef } from '@angular/core';
import { NoticeComponent } from '../../../shared/alerts/notice/notice.component';
import { SignalService } from '../../../shared/services/signal.service';
import { ExcelService } from '../../../shared/services/excel.service';
import { ListSerialComponent } from '../../../shared/alerts/list-serial/list-serial.component';
import { take } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { DetailsComponent } from '../details/details.component';
import { SanitizeSrcSignalPipe } from '../../../shared/pipe/sanitizer-src-pipe.';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { MatDialog } from '@angular/material';
import { SucessCreateAccountComponent } from '../../../shared/alerts/sucess-create-account/sucess-create-account.component';
import { AppService } from '../../../app.service';



@Component({
  selector: 'app-thumb-filtro',
  templateUrl: './thumb-filtro.component.html',
  styleUrls: ['./thumb-filtro.component.scss']
})
export class ThumbFiltroComponent implements OnInit {

  @Input() signal: any;
  @Input() type: any;
  @Input() user: any;

  star = [1, 2, 3, 4, 5];
  starv2 = Array.from({ length: 5 }, (v, k) => k);
  sinal;
  photo;
  photoWidth = 0;
  photoHeight = 0;
  assinantes = [];
  hideButtonForum = false;
  service = "Desktop";

  sinalId = false;
  i = 0;
  signalsGetInfo = [];
  signalDetails = null;
  loading = false;
  loadingImg = false;

  fakeUser: boolean = false;
  mobileFlagAppNew: boolean = true;

  constructor(
    private dialog: MatDialog,
    private _route: Router,
    private signalService: SignalService,
    private excelService: ExcelService,
    private sanitizeSrcSignalPipe: SanitizeSrcSignalPipe,
    private renderer: Renderer2,
    private _appService: AppService,
    private element: ElementRef,
  ) {

  }

  async ngOnInit() {
    this._appService.getFakeLogin().subscribe(flag => {
      this.fakeUser = flag;
    });
    this._appService.getMobileAppNew().subscribe(flag => {
      this.mobileFlagAppNew = flag;
    });
    this._appService.getMobile().subscribe(flag => {
      this.service = flag;
    });
    this.hideButtonForum = false;
    if (window.location.href.split("/")[4] == 'perfil/sinais') {
      this.hideButtonForum = true;
    }
    //Script que permite abrir apenas 1 details
    let details = Array.from(document.querySelectorAll('details'))
    details.forEach((targetDetail) => {
      targetDetail.addEventListener("click", () => {
        details.forEach((detail) => {
          if (detail !== targetDetail) {
            detail.removeAttribute("open");
          }
        });
      });
    });

    this.assinantes = this._appService.getAssinantes(); /* luizotavioautomacao@gmail.com  */

    let user: any = this._appService.getUser().value;
    this.assinantes.forEach((_email) => {
      if (user.auth.email == _email) {
        // sessionStorage.setItem('user', JSON.stringify(user));
        this.sinalId = true;
      }
    })
    this.renderer.setStyle(this.element.nativeElement.offsetParent, 'overflow', 'visible');
    this.user = await this._appService.getUser().value;
  }

  notice(params) {
    return this.dialog.open(NoticeComponent, { data: params })
  }

  evaluation(val, $event = null) {
    if (this.fakeUser) { this.loginToContinue(); return }

    $event ? $event.stopPropagation() : null
    this.signalService.evaluation(this.signal._id, val).pipe(take(1)).subscribe(async (res: any) => {
      if (res.code == 200) {
        this.signal.evaluation = await res.evo;
      }
    })
  }

  voted(star): boolean {
    return this.signal.evaluation.votes.findIndex(x => x.id == this.user._id && x.star == star) >= 0 ? true : false;
  }

  get isSame(): boolean {
    return this.user.email == this.signal.email;
  }

  preview(s) {
    this._route.navigate([`${environment.url_version}/planos`]);
  }

  importAsSession(signal) {
    this.dialog.open(ListSerialComponent).afterClosed().pipe(take(1)).subscribe((serial) => {
      if (!serial) {
        return;
      }
      this.signalService.dowloadFile(signal._id, { serial: serial }).pipe(take(1)).subscribe(async (file: any) => {
        if (file.code === 200 || file.code == 200200) {
          try {
            var blob = await new Blob([file.file], { type: "text/plain;charset=utf-8" });
            await saveAs(blob, signal._id + '.dma');

            this.dialog.open(NoticeComponent, {
              data: {
                message: 'Arquivo baixado com sucesso',
                confirmButtonText: 'Ok',
                timer: 5
              }
            })
          }
          catch (er) {
            this.dialog.open(NoticeComponent, {
              data: {
                message: 'O arquivo não pode ser baixado: ' + er,
                confirmButtonText: 'Ok',
                timer: 5
              }
            })
          }
        } else {
          this.dialog.open(NoticeComponent, {
            data: {
              message: 'O arquivo não pode ser baixado: ' + file.msg || file.error,
              confirmButtonText: 'Ok',
              timer: 5
            }
          })
        }
      })
    })
  }

  getImage(img) {
    let data = this.sanitizeSrcSignalPipe.transform(img);
    return data['changingThisBreaksApplicationSecurity'];
  }

  detail(signal) {
    if (this.fakeUser) { this.loginToContinue(); return }
    this.signalService.getSignalById(signal._id).pipe(take(1)).subscribe((resp: any) => {
      let owner = resp.owner;
      let sig = { owner, ...resp.signal }
      this.dialog.open(DetailsComponent, { data: sig })
    });
  }

  closeDetail() {
    this.dialog.closeAll();
  }

  importAsPlan(element) {
    let b = [element];
    this.excelService.exportAsExcelFile(this.signalService.toExcel(b), '' + element._id);
  }

  openAssociationFilter(signal: any) {
    localStorage.setItem('previousUrlFilter', this._route.url);
    this._route.navigate([`osciloscopio/filtros/association/${signal._id}`]);
  }

  deleteSignal(signal) {
    if (this.fakeUser) { this.loginToContinue(); return }

    this.dialog.open(DeleteSignalNotificationComponent, {
      data: {
        signalId: signal._id,
      },
      disableClose: true
    })

    // this.signalService.getSignalById(signal._id).pipe(take(1)).subscribe((resp: any) => { 
    //   let owner = resp.owner;
    //   let sig = { owner, ...signal}
    //   this.dialog.open(NotificarExclusaoComponent, {data: sig})
    // }) 

  }

  download_software(sinal) {
    if (this.fakeUser) { this.loginToContinue(); return }

    if (this.mobileFlagAppNew || localStorage.getItem('software-new-mobile') == 'true') {
      // const previousUrl = window.location.href;
      const url = `${environment.url_version}/download-signal/${sinal._id}`;
      window.location.href = url;
      // window.location.href = previousUrl;
      return
    }

    this.sinal = sinal;
    if (sessionStorage.getItem('software-new') !== 'true') {
      this.dialog.open(SucessCreateAccountComponent, {
        data: {
          title: 'Aviso!',
          icon_title: '&#xE85A;',
          message: 'Para fazer downloads de sinais é necessário abrir o site pelo software new tecnoscópio!!',
          buttonConfirm: 'Continuar',
          buttonDecline: 'Cancelar',
          icon_button: '&#xe044;',
          background: '#ffcc00',
          timer: 0
        },
        disableClose: true
      }).beforeClose().pipe(take(1)).subscribe((res) => {
        this.returnSendAcceptOrRecuseAlert();
      });
    } else {
      this._appService.acceptOrRecuseAlert(true);
      this.returnSendAcceptOrRecuseAlert();
    }
  }

  returnSendAcceptOrRecuseAlert() {
    let acceptRecuse = this._appService.sendAcceptOrRecuseAlert();
    if (acceptRecuse.value) {

      const params = ['module', 'brand', 'model', 'year', 'motor', 'injSystem', 'signalType', 'sett']
      for (const key of params) {
        if (!this.sinal[key]) Object.assign(this.sinal, { [key]: "none" })
      }

      let string = "{";

      Object.keys(this.sinal).map((key) => {
        if (
          key == "brand" ||
          key == "model" ||
          key == "module" ||
          key == "motor" ||
          key == "name" ||
          key == "sett" ||
          key == "year" ||
          key == "injSystem" ||
          key == "_id" ||
          key == "signalType"
        )
          string += `"${key}":"${this.sinal[key]}",`;
      });

      // remover ultimo caractere
      string = string.substring(0, string.length - 1);
      string += "}";
      console.log(string);
    } else {
    }
  }

  objectSignalInfo(id, retorno) {
    console.log(id, retorno)
    const obj = {
      _id: id,
      retorno: retorno
    }
    return obj;
  }

  openSummary(signal) {
    // console.log(this.signalsGetInfo)
    let signalExiste = false;

    for (let index = 0; index < this.signalsGetInfo.length; index++) {
      if (this.signalsGetInfo[index]._id == signal) {
        signalExiste = true;
        this.signalDetails = this.signalsGetInfo[index];
      }
    }
    if (signalExiste) {
      console.log('Sinal já existe')
      console.log(this.signalDetails)
    } else {
      this.loading = true;
      try {
        this.signalService.getDiagramSignalForum(signal).subscribe((res: any) => {
          if (res.code == 200) {
            //Sucesso
            // console.log(res)
            this.signalsGetInfo.push(this.objectSignalInfo(signal, res.retorno));
            this.loading = false;
          } else {
            //Falha
            this.signalsGetInfo.push(this.objectSignalInfo(signal, res.retorno));
            this.loading = false;
          }
        },
          (error) => {
            //Falha
            this.signalsGetInfo.push(this.objectSignalInfo(signal, error.error.code));
            this.loading = false;
          });
      } catch (error) {
        //Falha
        this.loading = false;
      }
    }
  }

  nameView(name) {
    try {
      const formataNome = str => {
        return str.toLowerCase().replace(/(?:^|\s)(?!da|de|do)\S/g, l => l.toUpperCase());
      };
      let parts = name.split(' ');
      return formataNome(`${parts[0]} ${parts[parts.length - 1]}`);
    } catch (error) {

    }
  }

  ratioImg(photo) {
    this.loadingImg = true;
    try {
      this.photo = this.getImage(photo)
      let im = new Image;
      im.src = this.photo;
      if (im.width == undefined && im.height == undefined) {
        return
      }
      var ratio = Math.min(200 / im.width, 200 / im.height);
      var widthC = im.width * ratio;
      var heightC = im.height * ratio;
      this.photoWidth = Math.trunc(widthC);
      this.photoHeight = Math.trunc(heightC);

      // console.log(this.photoWidth, this.photoHeight)
      this.loadingImg = false;
      return this.photo;
    } catch (error) {
      console.log("Erro")
      this.loadingImg = false;
      return this.photo;
    }
  }

  changeModal(event) {
    var modal = document.getElementById("myModal");
    modal.style.display = event;
  }

  openInfoModal(id, type, signal) {
    if (this.fakeUser) { this.loginToContinue(); return }

    // console.log(signal)
    if (this.user.permissao.indexOf(this.signal.permissaoId) > -1) {
      this.dialog.open(InfoAtrelamentoComponent, {
        data: {
          type: type,
          sinalId: signal._id,
          id: id,
        },
        disableClose: true,
        width: '100vw'
      }).beforeClose().pipe(take(1)).subscribe((res) => {

      });
    } else {
      this.noPermission();
    }
  }

  noPermission() {
    this.dialog.open(SucessCreateAccountComponent, {
      data: {
        title: 'Sem Permissão',
        icon_title: '&#xe14b;',
        message: 'Você não tem permissão para acessar essa informação!ﾠﾠﾠﾠﾠFaça uma assinatura para ter acesso.',
        buttonConfirm: '',
        buttonDecline: 'Tudo bem',
        icon_button: '&#xE5C8;',
        background: '#cc3300',
        timer: 0
      },
      disableClose: true
    }).beforeClose().pipe(take(1)).subscribe((res) => {
      this._route.navigate([`${environment.url_version}/planos`]);
    });
  }

  loginToContinue() {
    this.dialog.open(LoginToContinueComponent, { disableClose: true });
  }
}
