import { Component, Input, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit, OnDestroy {
  @Input() banners: { image: string, alt?: string }[] = [
    { image: 'banner1.png', alt: 'Banner 1' },
    { image: 'banner2.png', alt: 'Banner 2' },
    { image: 'banner3.png', alt: 'Banner 3' }
  ];

  currentSlideIndex = 0;
  slideInterval: any = null;

  ngOnInit() {
    this.startSlideInterval();
  }

  ngOnDestroy() {
    this.clearSlideInterval();
  }

  nextSlide(): void {
    this.currentSlideIndex = (this.currentSlideIndex + 1) % this.banners.length;
    this.restartSlideInterval();
  }

  prevSlide(): void {
    this.currentSlideIndex = (this.currentSlideIndex - 1 + this.banners.length) % this.banners.length;
    this.restartSlideInterval();
  }

  goToSlide(index: number): void {
    if (index !== this.currentSlideIndex) {
      this.currentSlideIndex = index;
      this.restartSlideInterval();
    }
  }

  private startSlideInterval(): void {
    this.slideInterval = setInterval(() => {
      this.nextSlide();
    }, 5000);
  }

  private clearSlideInterval(): void {
    if (this.slideInterval) {
      clearInterval(this.slideInterval);
      this.slideInterval = null;
    }
  }

  private restartSlideInterval(): void {
    this.clearSlideInterval();
    this.startSlideInterval();
  }
}