import { environment } from './../../../../environments/environment';
import { ForgotPasswordService } from '../../../shared/components/forgot/forgot-password/forgot-password.service';
import { AppService } from './../../../app.service';
import { ModalService } from './../../../shared/services/modal.service';
import { Component, OnInit } from '@angular/core';
import { UserAuthenticationService } from '../user-authentication.service';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit {

  constructor(
    private userAuthenticationService: UserAuthenticationService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private ForgotPasswordService: ForgotPasswordService
  ) { }

  ngOnInit() {
    this.activateUserAccount();
  }

  activateUserAccount() {
    localStorage.setItem('token', this.route.snapshot.paramMap.get('token'));
    try {
      this.userAuthenticationService.activateAccount().subscribe((response: HttpResponse<any>) => {
        if (response.status === 200) {
          // Sucesso
          localStorage.clear();
          sessionStorage.clear();
          this.ForgotPasswordService.storageEmail(response.body.user.auth.email);
          this.appService.emit(response.body.user, response.body.token, response.body.user.acesskey, '');
          this.router.navigate([`${environment.url_version}/filtros`]);
          this.modalService.openModal('Sucesso', 'Sua conta foi ativada com sucesso!', 'success', 'Ok').subscribe(() => { });
        } else {
          // Falha
          this.modalService.openModal('Falha!', response.body.error || 'Algo deu errado. Por favor, tente novamente.', 'error', 'Ok').subscribe();
          this.router.navigate([`${environment.url_version}/user/login`]);
        }
      },
        (error) => {
          // Falha
          this.modalService.openModal('Falha!', error.error.error || 'Algo deu errado. Por favor, tente novamente.', 'error', 'Ok').subscribe();
          this.router.navigate([`${environment.url_version}/user/login`]);
        });
    } catch (error) {
      // Falha
      this.modalService.openModal('Falha!', error.error.error || 'Algo deu errado. Por favor, tente novamente.', 'error', 'Ok').subscribe();
      this.router.navigate([`${environment.url_version}/user/login`]);
    }
  }

}
