import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SharedService } from '../shared.service';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class FormularioService {

  constructor(private _http: HttpClient) { }


  changePassword(form, type = 'user') {
    return this._http.put(`${environment.api_server}/api/module/user/new-password`, form, { headers: SharedService.header });
  }

  update(user: any): Observable<HttpResponse<any>> {
    return this._http.put<HttpResponse<any>>(`${environment.api_server}/api/module/user`, user, { headers: SharedService.header, observe: 'response' });
  }

  create(user) {

    let objeto = {
      user: user,
      url_version: environment.url_version
    }
    return this._http.post(`${environment.api_server}/api/module/user/create`, objeto);
  }

  checkEmail(e, id = '') {
    return this._http.post(`${environment.api_server}/api/module/user/email/`, { email: e, id: id })
  }
  checkNickname(payload): Observable<HttpResponse<any>> {
    return this._http.post<HttpResponse<any>>(`${environment.api_server}/api/module/user/nickname`, payload, { headers: SharedService.header, observe: 'response' });
  }
  checkCPFCNPJ(n, id) {
    return this._http.post(`${environment.api_server}/api/module/user/cpfcnpj/`, { person: n, id: id })
  }



  validCNPJ(cnpj) {
    return new Promise((resolve, reject) => {
      cnpj = cnpj.replace(/[^\d]/g, '');
  
      if (cnpj.length !== 14 || !/[0-9]{14}/.test(cnpj)) {
        resolve(false);
      }
  
      // Validação do CNPJ
      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
  
      for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) pos = 9;
      }
  
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  
      if (resultado !== parseInt(digitos.charAt(0))) {
        resolve(false);
      }
  
      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
  
      for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) pos = 9;
      }
  
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  
      if (resultado !== parseInt(digitos.charAt(1))) {
        resolve(false);
      }
  
      resolve(true);
    });
  }

  validCPF(cpf) {
    return new Promise((resolve, reject) => {
      cpf = cpf.replace(/[^\d]/g, '');

      if (cpf.length !== 11 || !/[0-9]{11}/.test(cpf)) {
        resolve(false);
      }

      let soma = 0;
      let resto;

      for (let i = 1; i <= 9; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
      }

      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) {
        resto = 0;
      }

      if (resto !== parseInt(cpf.substring(9, 10))) {
        resolve(false);
      }

      soma = 0;

      for (let i = 1; i <= 10; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
      }

      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) {
        resto = 0;
      }

      if (resto !== parseInt(cpf.substring(10, 11))) {
        resolve(false);
      }
      resolve(true);
    });
  }

  sendPhotoUser(params: any): Observable<HttpResponse<any>> {
    return this._http.post<HttpResponse<any>>(`${environment.api_server}/api/module/user/update-photo`, params, { headers: SharedService.header, observe: 'response' });
  }

}
