import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class StyleSelectorService {
    constructor(private router: Router) {
        this.setStyleModeAttribute(window.location.href);
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: any) => {
            this.setStyleModeAttribute(event.url);
        });
    }

    private setStyleModeAttribute(url: string): void {
        const isDiagramRoute = url.includes('/diagrama');
        document.body.setAttribute('data-style-mode', isDiagramRoute ? 'diagram' : 'default');
        console.log(`Modo de estilo definido como: ${isDiagramRoute ? 'diagram' : 'default'}`);
    }
}