import { ModalService } from '../../../shared/services/modal.service';
import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material';
import { take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { TokenComponent } from '../../../shared/alerts/token/token.component';
import { LoginService } from '../../../shared/login/login.service';
import { Controls } from '../../../shared/forms/controls';
import { ErrorState } from '../../../shared/forms/error-state';
import { AppService } from '../../../app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from '../../../shared/alerts/token/token.service';
import { Meta, Title } from '@angular/platform-browser';
import { RecoverEmailComponent } from '../../../shared/alerts/recover-email/recover-email.component';
import { UserAuthenticationService } from '../user-authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  private url: String;
  session = false;

  matcher = new ErrorState();
  controls = new Controls();
  formEmail = this.controls.email;
  formPwd = this.controls.pwdNoLength;
  onCapsLock = false;
  loading = false;

  _LOG = false;
  emailMemory;

  routerLink_URL_cadastro = environment.url_version == '' ? `${environment.url_version}/user/register` : `/${environment.url_version}/user/register`;
  routerLink_URL_filtro = `${environment.url_version}/filtros`;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private dialog: MatDialog,
    private _loginService: LoginService,
    private tokenService: TokenService,
    private _appService: AppService,
    private renderer: Renderer2,
    private element: ElementRef,
    private titleService: Title,
    private metaService: Meta,
    private modalService: ModalService,
    private userAuthenticationService: UserAuthenticationService
  ) { }

  ngOnInit() {
    let typeService = "Desktop"
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) typeService = "Mobile";
    else typeService = "Desktop";

    this.titleService.setTitle('New tecnoscópio - Entrar');
    this.metaService.addTags([
      { name: 'description', content: 'Mais de 5 mil pessoas usam o New Tecnoscopio para descobrir e solucionar problemas com ajuda de mais de 3 mil sinais.' },
    ]);

    this.emailMemory = sessionStorage.getItem('email');
    if (this.emailMemory) this.formEmail.patchValue(this.emailMemory);

    this.renderer.setStyle(this.element.nativeElement.offsetParent, 'overflow', 'visible');

    this._route.data.pipe(take(1)).subscribe((res: any) => {
      this.url = res.type;
    });

    if (localStorage.getItem('sessao') == 'true') {
      this.session = true;
    }

    if (typeService == 'Mobile') {
      setTimeout(() => {
        const loginElement = document.querySelector('.login-card');
        if (loginElement) {
          loginElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 200);
    }

    document.addEventListener('keyup', (e) => {
      try {
        if (e.getModifierState('CapsLock')) {
          this.onCapsLock = true;
        } else {
          this.onCapsLock = false;
        }
      } catch (error) {

      }
    });
  }

  logout() {
    this._appService.setLoginUser(false);
    sessionStorage.clear();
    localStorage.clear();
    this.session = false;
    this._appService.emit(undefined, undefined, undefined);
    this._appService.setFakeLogin(false);
  }

  recoverEmail() {
    return this.dialog.open(RecoverEmailComponent, { disableClose: true }).afterClosed().pipe(take(1)).subscribe((cpf_cnpj: any) => {
      if (cpf_cnpj) {
        try {
          this._loginService.recoverEmail({ cpf_cnpj: cpf_cnpj }).subscribe((res: any) => {
            if (res.code == 200) {
              if (res.email !== null) {
                this.modalService.openModal(
                  'Sucesso',
                  `Encontramos o e-mail: ${res.email}`,
                  'success',
                  'É meu e-mail',
                  'Não é meu e-mail'
                ).pipe(take(1)).subscribe(async (callback) => {
                  if (callback) {
                    this.formEmail.patchValue(res.email);
                    document.getElementById("inputPassword").focus();
                  }
                });
              } else {
                this.modalService.openModal('Falha!', res.msg || res.error || 'Algo deu errado. Por favor, tente novamente.', 'error', 'Ok').subscribe((result) => { });
              }
              return;
            } else {
              this.modalService.openModal('Falha!', res.msg || res.error || 'Algo deu errado. Por favor, tente novamente.', 'error', 'Ok').subscribe((result) => { });
              return;
            }
          },
            (error) => {
              this.modalService.openModal('Falha!', error.error.msg || error.error || 'Algo deu errado. Por favor, tente novamente.', 'error', 'Ok').subscribe((result) => { });
              return;
            }
          );
        } catch (error) {
          this.modalService.openModal('Falha!', 'Algo deu errado. Por favor, tente novamente.', 'error', 'Ok').subscribe((result) => { });
          return;
        }
      }
    });
  }

  recoverPwd() {
    return this.dialog.open(TokenComponent, { disableClose: true }).afterClosed().pipe(take(1)).subscribe((email: any) => {
      if (email) {
        this.userAuthenticationService.createPasswordToken({ email: email }).pipe(take(1)).subscribe((em: any) => {
          if (em.code == 200) {
            this.modalService.openModal('E-mail Enviado', 'E-mail enviado. Por favor, siga as etapas indicadas no e-mail.', 'success', 'Ok');
          } else {
            this.modalService.openModal('Erro', em.msg || em.error || 'Algo deu errado. Por favor, tente novamente.', 'error', 'Ok').subscribe((result) => { });
          }
        })
      }
    });
  }

  get form() {
    return {
      email: this.formEmail.value,
      password: this.formPwd.value
    }
  }

  async login(event) {
    if (this.form.email == '' || this.form.password == '') {
      this.modalService.openModal('Aviso', 'Por favor, preencha todos os campos antes de continuar.', 'warning', 'Entendi').subscribe((result) => { });
      return;
    }

    this.loading = true;

    if (this.form.email == 'visitante') return;
    if (this.form.email == 'visitante@ind.br') return;

    this._loginService.authentication(this.form).pipe(take(1)).subscribe(async (res: any) => {
      if (res.code == 200) {
        this.loading = false;
        if (res.user._id == '5f71f7d333300b5662abaede') return;
        this.logout();
        localStorage.clear();
        sessionStorage.clear();
        this.tokenService.storageEmail(res.user.auth.email);

        await this._appService.emit(res.user, res.token, res.user.acesskey, 'from login component');

        const urlRedirect: any = await this._appService.getUrlRedirect();
        if (urlRedirect.value !== null && urlRedirect.value !== undefined) {
          if (urlRedirect.value.flag == true) {
            const splitUrl = decodeURI(urlRedirect.value.url).split('/');
            if (splitUrl.length >= 5) {
              const checkRouterParams = splitUrl[4].split('?');
              if (checkRouterParams.length == 1) {
                this._router.navigate([`${environment.url_version}/${checkRouterParams[0]}`]);
              }
              if (checkRouterParams.length == 2) {
                const params = checkRouterParams[1].split('&');
                const queryParams: any = {}
                const objectParams = [];
                params.forEach(param => {
                  objectParams.push(param.split('='));
                });
                objectParams.forEach(object => {
                  queryParams[`${object[0]}`] = object[1].replace('%2F', '/');
                });
                this._router.navigate([`${environment.url_version}/${checkRouterParams[0]}`], { queryParams: queryParams });
                return
              }
              if (checkRouterParams.length >= 3) {
                environment.first_screen.forEach(router => {
                  if (router.flag == true) {
                    const queryParams: string = router.value.split('=')[1];
                    if (queryParams == 'Oscilograma' || queryParams == 'Diagrama') {
                      this._router.navigate([`${environment.url_version}/${router.value.split('?')[0]}`], { queryParams: { tipoBusca: queryParams, modulo: 'Automotivo-carros' } });
                    } else {
                      this._router.navigate([`${environment.url_version}/${router.value.split('?')[0]}`], { queryParams: { tipoBusca: queryParams } });
                    }
                  }
                });
                return
              }
            }
            this._appService.sendUrlRedirect(null);
          } else {
            environment.first_screen.forEach(router => {
              if (router.flag == true) {
                const queryParams: string = router.value.split('=')[1];
                if (queryParams == 'Oscilograma' || queryParams == 'Diagrama') {
                  this._router.navigate([`${environment.url_version}/${router.value.split('?')[0]}`], { queryParams: { tipoBusca: queryParams, modulo: 'Automotivo-carros' } });
                } else {
                  this._router.navigate([`${environment.url_version}/${router.value.split('?')[0]}`], { queryParams: { tipoBusca: queryParams } });
                }
              }
            });
            return
          }
        } else {
          environment.first_screen.forEach(router => {
            if (router.flag == true) {
              const queryParams: string = router.value.split('=')[1];
              if (queryParams == 'Oscilograma' || queryParams == 'Diagrama') {
                this._router.navigate([`${environment.url_version}/${router.value.split('?')[0]}`], { queryParams: { tipoBusca: queryParams, modulo: 'Automotivo-carros' } });
              } else {
                this._router.navigate([`${environment.url_version}/${router.value.split('?')[0]}`], { queryParams: { tipoBusca: queryParams } });
              }
            }
          });
          return
        }

        environment.first_screen.forEach(router => {
          if (router.flag == true) {
            const queryParams: string = router.value.split('=')[1];
            if (queryParams == 'Oscilograma' || queryParams == 'Diagrama') {
              this._router.navigate([`${environment.url_version}/${router.value.split('?')[0]}`], { queryParams: { tipoBusca: queryParams, modulo: 'Automotivo-carros' } });
            } else {
              this._router.navigate([`${environment.url_version}/${router.value.split('?')[0]}`], { queryParams: { tipoBusca: queryParams } });
            }
          }
        });

      } else if (res.code == 404) {
        this.loading = false;
        this.modalService.openModal('Aviso', 'Usuário ainda não está cadastrado.', 'warning', 'Quero me cadastrar', 'Tentar novamente').subscribe((result) => {
          if (result) {
            this._router.navigate([`${environment.url_version}/user/register`]);
          }
        });
      } else if (res.code == 400) {
        this.loading = false;
        this._router.navigate([`${environment.url_version}/checkout/guest/66abced85dd09cc445bcc0a2`], {
          queryParams: { idUser: res.idUser }
        });
      } else if (res.code == 403) {
        this.loading = false;
        this.modalService.openModal('Aviso', 'Esta conta não está ativada.', 'warning', 'Quero ativar minha conta', 'Cancelar').subscribe((result) => {
          if (result) {
            this.userAuthenticationService.createActivationToken({ login: this.formEmail.value }).pipe(take(1)).subscribe((em: any) => {
              if (em.code == 200) {
                this.loading = false;
                this.modalService.openModal('E-mail Enviado', 'E-mail enviado com sucesso! Por favor, siga as etapas indicadas no e-mail.', 'success', 'Ok').subscribe((result) => { });
              } else {
                this.modalService.openModal('Erro', em.msg || em.error || 'Algo deu errado. Por favor, tente novamente.', 'error', 'Ok').subscribe((result) => { });
              }
            })
          }
        });
      } else if (res.code == 401) {
        this.loading = false;
        this.modalService.openModal('Aviso', 'Oops, a senha está incorreta...', 'error', 'Ok');
      } else {
        this.loading = false;
        this.modalService.openModal('Erro', res.msg || res.error || 'Algo deu errado. Por favor, tente novamente.', 'error', 'Ok').subscribe((result) => { });
      }
    });

    if (event) {
      event.preventDefault();
    }
  }

  navegatePlataforma() {
    this._router.navigate([`${environment.url_version}/filtros`]);
  }
}