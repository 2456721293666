import { PlanosService } from './../../_new-tecnoscopio-v2/new-perfil/planos.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SafeModule } from './../../safe.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoticeComponent } from './notice/notice.component';
import { MaterialModule } from '../material/material.module';
import { TokenComponent } from './token/token.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TokenService } from './token/token.service';
import { NotificationComponent } from './notification/notification.component';
import { PipeModule } from '../pipe/pipe.module';
import { SerialComponent } from './serial/serial.component';
import { LoadingComponent } from './loading/loading.component';
import { VideoComponentAlert } from './video/video.component';
import { VideoService } from './video/video.service';
import { PreviewComponent } from './preview/preview.component';
import { ListSerialComponent } from './list-serial/list-serial.component';
import { AlertService } from './alert.service';
import { VehicleInfoBasicComponent } from './vehicle-info-basic/vehicle-info-basic.component';
import { GeneratedRecurrenceComponent } from './generated-recurrence/generated-recurrence.component';
import { NotificarExclusaoComponent } from '../notificar-exclusao/notificar-exclusao.component';
import { TermsComponent } from './terms/terms.component';
import { SucessCreateAccountComponent } from './sucess-create-account/sucess-create-account.component';
import { ParcelamentoFuturoComponent } from './parcelamento-futuro/parcelamento-futuro.component';
import { InfoAtrelamentoComponent } from './info-atrelamento/info-atrelamento.component';
import { PagerService } from '../services/Pager/pager';
import { CertificadoComponent } from './certificado/certificado.component';
import { NewLoadingComponent } from './new-loading/new-loading.component';
import { AlertApiErrorComponent } from './alert-api-error/alert-api-error.component';
import { LoginToContinueComponent } from './login-to-continue/login-to-continue.component';
import { RecoverEmailComponent } from './recover-email/recover-email.component';
import { FilterDiagramComponent } from './filter-diagram/filter-diagram.component';
import { InputGetCupomComponent } from './input-get-cupom/input-get-cupom.component';
import { RenovarPlanoCartaoComponent } from './renovar-plano-cartao/renovar-plano-cartao.component';
import { ViewVideoComponent } from './view-video/view-video.component';
import { ViewTutorialComponent } from './view-tutorial/view-tutorial.component';
import { ViewFotoComponent } from './view-foto/view-foto.component';
import { LoadingFiltroV2Component } from './loading-filtro-v2/loading-filtro-v2.component';
import { ViewFilesCurseComponent } from './view-files-curse/view-files-curse.component';
import { ModalComponent } from './modal/modal.component';
import { AssinanteService } from '../services/assinante.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PipeModule,
    SafeModule,
    PdfViewerModule
  ],
  declarations:
    [
      NoticeComponent,
      TokenComponent,
      NotificationComponent,
      SerialComponent,
      LoadingComponent,
      VideoComponentAlert,
      PreviewComponent,
      ListSerialComponent,
      VehicleInfoBasicComponent,
      GeneratedRecurrenceComponent,
      NotificarExclusaoComponent,
      TermsComponent,
      SucessCreateAccountComponent,
      ParcelamentoFuturoComponent,
      InfoAtrelamentoComponent,
      CertificadoComponent,
      NewLoadingComponent,
      AlertApiErrorComponent,
      LoginToContinueComponent,
      RecoverEmailComponent,
      FilterDiagramComponent,
      InputGetCupomComponent,
      RenovarPlanoCartaoComponent,
      ViewVideoComponent,
      ViewTutorialComponent,
      ViewFotoComponent,
      LoadingFiltroV2Component,
      ViewFilesCurseComponent,
      ModalComponent
    ],
  exports: [
    NoticeComponent,
    TokenComponent,
    VideoComponentAlert,
    GeneratedRecurrenceComponent,
    NotificarExclusaoComponent,
    RecoverEmailComponent,
  ],
  entryComponents: [
    NoticeComponent,
    GeneratedRecurrenceComponent,
    TokenComponent,
    RecoverEmailComponent,
    NotificationComponent,
    SerialComponent,
    LoadingComponent,
    VideoComponentAlert,
    PreviewComponent,
    ListSerialComponent,
    VehicleInfoBasicComponent,
    NotificarExclusaoComponent,
    RenovarPlanoCartaoComponent,
    TermsComponent,
    SucessCreateAccountComponent,
    LoadingFiltroV2Component,
    InputGetCupomComponent,
    LoginToContinueComponent,
    NewLoadingComponent,
    ViewVideoComponent,
    ViewTutorialComponent,
    ViewFotoComponent
  ],
  providers: [
    TokenService,
    VideoService,
    AlertService,
    PagerService,
    AssinanteService,
    PlanosService
  ]
})
export class AlertsModule { }
