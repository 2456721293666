import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  MatButtonModule, MatCheckboxModule, MatMenuModule,
  MatCardModule, MatToolbarModule, MatIconModule,
  MatSidenavModule, MatProgressBarModule, MatDialogModule,
  MatFormFieldModule, MatInputModule, MatGridListModule,
  MatSelectModule, MatTooltipModule, MatProgressSpinnerModule,
  MatStepperModule, MatTableModule, MatPaginatorModule,
  MatExpansionModule, MatDividerModule, MatListModule,
  MatSliderModule, GestureConfig, MatSlideToggleModule, MatRadioModule, MatAutocompleteModule, MatChipsModule
} from '@angular/material';
import { TextMaskModule } from 'angular2-text-mask';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

@NgModule({
  imports: [
    CommonModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatCardModule,
    MatProgressBarModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatGridListModule,
    MatSelectModule,
    MatDialogModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatDividerModule,
    MatListModule,
    TextMaskModule,
    // AngularEditorModule
    //  NgxMaskModule,
  ],
  exports: [
    CommonModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatCardModule,
    MatProgressBarModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatGridListModule,
    MatSelectModule,
    MatDialogModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatDividerModule,
    MatListModule,
    TextMaskModule,
    //  NgxMaskModule,
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig }
  ],
  declarations: []
})
export class MaterialModule { }
