import { SignalDownloadService } from './services/signal-download.service';
import { ErrorDecryptInterceptor } from './services/error.decrypt.interceptor';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MaterialModule } from './material/material.module';
import { SharedService } from './shared.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AlertsModule } from './alerts/alerts.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PipeModule } from './pipe/pipe.module';
import { TokenGuard } from './guards/token.guard';
import { ExcelService } from './services/excel.service';
import { NewSinaisFiltroComponent } from '../_new-tecnoscopio-v2/new-sinais-filtro/new-sinais-filtro.component';
import { FormDebugComponent } from './form-debug/form-debug.component';
import { ThumbFiltroComponent } from '../_new-tecnoscopio-v2/new-sinais-filtro/thumb-filtro/thumb-filtro.component';
import { FiltrosComponent } from '../_new-tecnoscopio-v2/new-sinais-filtro/filtros/filtros.component';
import { DirectiveModule } from './directive/directive.module';
import { DetailsComponent } from '../_new-tecnoscopio-v2/new-sinais-filtro/details/details.component';
import { FormularioService } from './formulario/formulario.service';
import { FooterComponent } from './footer/footer.component';
import { ControlErroComponent } from './css-erro/control-erro/control-erro.component';
import { DiagramaFiltroComponent } from '../_new-tecnoscopio-v2/new-sinais-filtro/diagrama-filtro/diagrama-filtro.component';
import { ThumbDiagramaComponent } from '../_new-tecnoscopio-v2/new-sinais-filtro/thumb-diagrama/thumb-diagrama.component';
import { VerificaTokenComponent } from './login/verifica-token/verifica-token.component';
import { HttpRequestInterceptor } from './services/http.interceptor';
import { SafeModule } from '../safe.module';
import { VerificaTokenAppComponent } from './login/verifica-token-app/verifica-token-app.component';
import { LoginService } from './login/login.service';
import { LoginGuard } from './login/login.guard';
import { VerificaTokenMobileComponent } from './login/verifica-token-mobile/verifica-token-mobile.component';
import { BannerComponent } from './components/banner/banner.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ImageZoomModalComponent } from './components/image-zoom-modal/image-zoom-modal.component';
import { SignalDetailsModalComponent } from './components/signal-details-modal/signal-details-modal.component';

@NgModule({
  imports: [
    HttpClientModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AlertsModule,
    PipeModule,
    DirectiveModule,
    RouterModule,
    SafeModule
  ],
  exports: [
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AlertsModule,
    PipeModule,
    FormDebugComponent,
    FooterComponent,
    ThumbFiltroComponent,
    ThumbDiagramaComponent,
    ControlErroComponent,
    DiagramaFiltroComponent,
    VerificaTokenComponent,
    VerificaTokenAppComponent,
    VerificaTokenMobileComponent,
    BannerComponent,
    PaginationComponent,
    ImageZoomModalComponent,
    SignalDetailsModalComponent
  ],
  providers: [
    SharedService,
    LoginService,
    TokenGuard,
    LoginGuard,
    ExcelService,
    FormularioService,
    SignalDownloadService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorDecryptInterceptor, multi: true },
  ],
  declarations: [
    FiltrosComponent,
    ThumbFiltroComponent,
    ThumbDiagramaComponent,
    NewSinaisFiltroComponent,
    DetailsComponent,
    FormDebugComponent,
    FooterComponent, // foi necessário add "schemas: [ CUSTOM_ELEMENTS_SCHEMA ] "
    ControlErroComponent,
    DiagramaFiltroComponent,
    VerificaTokenComponent,
    VerificaTokenAppComponent,
    VerificaTokenMobileComponent,
    BannerComponent,
    PaginationComponent,
    ImageZoomModalComponent,
    SignalDetailsModalComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
