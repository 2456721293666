import { environment } from './../../../environments/environment';
import { AppService } from './../../app.service';
import { Injectable } from '@angular/core';
import { Router, Route, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlockAcessGuard implements CanLoad {

  constructor(
    private router: Router,
    private appService: AppService
  ) { }

  canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise(async (resolve) => {
      try {
        const user = await this.appService.getUser().value;
        if (user && user._id !== "5f71f7d333300b5662abaede") {
          resolve(true);
        } else {
          this.router.navigate([`${environment.url_version}/user/login`]);
          resolve(false);
        }
      } catch (error) {
        console.error('Erro ao verificar usuário:', error);
        this.router.navigate([`${environment.url_version}/user/login`]);
        resolve(false);
      }
    });
  }
}