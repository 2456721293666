import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Controls } from '../../../forms/controls';
import { ErrorState } from '../../../forms/error-state';

@Component({
  selector: 'app-forgot-email',
  templateUrl: './forgot-email.component.html',
  styleUrls: ['./forgot-email.component.scss']
})
export class ForgotEmailComponent implements OnInit {
  matcher = new ErrorState();
  controls = new Controls();
  formEmail = this.controls.emailForToken;
  isFocused = false;
  isValid = false;

  constructor(
    private dialog: MatDialogRef<ForgotEmailComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  ngOnInit() {
    this.checkValidity();
  }

  onInputChange(value: string) {
    this.checkValidity();
  }

  checkValidity() {
    const value = this.formEmail.value;

    if (!value) {
      this.isValid = false;
      return;
    }
    const numericValue = value.replace(/\D/g, '');
    if (numericValue.length === 11 || numericValue.length === 14) {
      this.isValid = true;
    } else {
      this.isValid = false;
    }
  }

  cancel() {
    this.dialog.close();
  }

  confirm() {
    if (this.isValid) {
      this.dialog.close(this.formEmail.value);
    }
  }

  cpfcnpjmask(event: any) {
    let numero = event.match(/\d/g);
    let numeroLength = 0;
    if (numero) {
      numeroLength = numero.join('').length;
    }
    if (numeroLength <= 11) {
      return [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
    } else {
      return [/[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
    }
  }
}