import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../../app.service';
import { environment } from '../../../../environments/environment';
import { TokenService } from '../../alerts/token/token.service';
import { ModalService } from '../../services/modal.service';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-verifica-token-scanner',
  templateUrl: './verifica-token-scanner.component.html',
  styleUrls: ['./verifica-token-scanner.component.scss']
})
export class VerificaTokenScannerComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private appService: AppService,
    private tokenService: TokenService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(data => {
      try {
        this.loginService.checkToken(data.token).subscribe(async (res: any) => {
          if (res.code == 200) {
            //Sucesso
            this.tokenService.storageEmail(res.user.auth.email);
            await this.appService.emit(res.user, data.token, res.user.acesskey, 'from login component');
            localStorage.setItem('preferenceScanner', data.permissao)
            this.appService.setScanner(true);
            this.appService.setMobileAppNew(false);
            this.appService.setFakeLogin(false);
            this.appService.setLoginUser(false);
            this.router.navigate([`${environment.url_version}/planos`]);
          } else {
            //Falha
            this.modalService.openModal('Falha!', res.msg || res.error || 'Algo deu errado. Por favor, tente novamente.', 'error', 'Ok').subscribe();
          }
        },
          (error) => {
            //Falha
            this.modalService.openModal('Falha!', error.error.msg || 'Algo deu errado. Por favor, tente novamente.', 'error', 'Ok').subscribe();
          });
      } catch (error) {
        //Falha
        this.modalService.openModal('Falha!', error.error.msg || 'Algo deu errado. Por favor, tente novamente.', 'error', 'Ok').subscribe();
      }
    })
  }

}
