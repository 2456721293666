import { ModalService } from './../../services/modal.service';
import { environment } from './../../../../environments/environment';
import { ForgotPasswordService } from '../../components/forgot/forgot-password/forgot-password.service';
import { AppService } from './../../../app.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-verifica-token-mobile',
  templateUrl: './verifica-token-mobile.component.html',
  styleUrls: ['./verifica-token-mobile.component.scss']
})
export class VerificaTokenMobileComponent implements OnInit {

  token;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private appService: AppService,
    private ForgotPasswordService: ForgotPasswordService,
    private modalService: ModalService,
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.token = data.token;
      try {
        this.loginService.checkToken(this.token).subscribe(async (res: any) => {
          if (res.code == 200) {
            //Sucesso
            this.ForgotPasswordService.storageEmail(res.user.auth.email);
            await this.appService.emit(res.user, this.token, res.user.acesskey, 'from login component');
            this.appService.setMobileAppNew(true);
            this.appService.setFakeLogin(false);
            this.appService.setLoginUser(false);
            this.router.navigate([`${environment.url_version}/filtros`]);
          } else {
            //Falha
            this.modalService.openModal('Falha!', res.msg || res.error || 'Algo deu errado. Por favor, tente novamente.', 'error', 'Ok').subscribe();
          }
        },
          (error) => {
            //Falha
            this.modalService.openModal('Falha!', error.error.msg || 'Algo deu errado. Por favor, tente novamente.', 'error', 'Ok').subscribe();
          });
      } catch (error) {
        //Falha
        this.modalService.openModal('Falha!', error.error.msg || 'Algo deu errado. Por favor, tente novamente.', 'error', 'Ok').subscribe();
      }
    })
  }

}
