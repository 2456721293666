import { UserAuthenticationModule } from './_new-tecnoscopio-v2/user-authentication/user-authentication.module';
import { LoginUserByAdminGuard } from './shared/guards/login-user-by-admin.guard';
import { BlockAcessGuard } from './shared/guards/block-acess.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { VerificaTokenComponent } from './shared/login/verifica-token/verifica-token.component';
import { LoginTokenGuard } from './shared/login/login-token.guard';
import { NewNavBarComponent } from './_new-tecnoscopio-v2/new-nav-bar/new-nav-bar.component';
import { TokenGuard } from './shared/guards/token.guard';
import { NewContatoModule } from './_new-tecnoscopio-v2/new-contato/new-contato.module';
import { FakeLoginGuard } from './shared/guards/fake-login.guard';
import { VerificaTokenAppComponent } from './shared/login/verifica-token-app/verifica-token-app.component';
import { PermissionGuard } from './shared/guards/permission.guard';
import { VerificaTokenMobileComponent } from './shared/login/verifica-token-mobile/verifica-token-mobile.component';

const routes: Routes = [
  {
    path: environment.url_version, component: NewNavBarComponent, children: [

      { path: '', loadChildren: 'app/_new-tecnoscopio-v2/new-index/new-index.module#NewIndexModule', canActivate: [FakeLoginGuard], resolve: { user: PermissionGuard } },
      { path: 'user', loadChildren: () => UserAuthenticationModule, canActivate: [FakeLoginGuard] },
      { path: 'user/recover/:token', loadChildren: 'app/shared/formulario/recover/recover.module#RecoverModule', resolve: { info: TokenGuard } },
      { path: 'perfil', loadChildren: 'app/_new-tecnoscopio-v2/profile/profile.module#ProfileModule', canLoad: [BlockAcessGuard], resolve: { user: PermissionGuard } },
      { path: 'pedido-diagrama', loadChildren: 'app/_new-tecnoscopio-v2/new-formulario-diagrama/new-formulario-diagrama.module#NewFormularioDiagramaModule', canActivate: [FakeLoginGuard], resolve: { user: PermissionGuard } },
      {
        path: 'filtros',
        loadChildren: () => environment.filtroV3
          ? import('../app/_new-tecnoscopio-v2/filters/filters.module').then(m => m.FiltersModule)
          : import('../app/_new-tecnoscopio-v2/new-filtro/new-filtro.module').then(m => m.NewFiltroModule),
        canActivate: [FakeLoginGuard],
        resolve: { user: PermissionGuard }
      },
      { path: 'cursos', loadChildren: 'app/_new-tecnoscopio-v2/courses/course.module#CourseModule', canActivate: [FakeLoginGuard], resolve: { user: PermissionGuard } },
      { path: 'curso', loadChildren: 'app/_new-tecnoscopio-v2/new-cursos/new-cursos.module#NewCursosModule', canActivate: [FakeLoginGuard], resolve: { user: PermissionGuard } },
      { path: 'planos', loadChildren: 'app/_new-tecnoscopio-v2/plans/plan.module#PlanModule', canActivate: [FakeLoginGuard], resolve: { user: PermissionGuard } },
      { path: 'checkout', loadChildren: 'app/_new-tecnoscopio-v2/checkout-guest/checkout-guest.module#CheckoutGuestModule', canActivate: [FakeLoginGuard], resolve: { user: PermissionGuard } },
      { path: 'noticias', loadChildren: 'app/_new-tecnoscopio-v2/news/new.module#NewModule', canActivate: [FakeLoginGuard], resolve: { user: PermissionGuard } },
      { path: 'qualidade', loadChildren: 'app/_new-tecnoscopio-v2/quality/quality.module#QualityModule', canActivate: [FakeLoginGuard], resolve: { user: PermissionGuard } },

      { path: 'sobre', loadChildren: () => NewContatoModule },
      { path: 'privacidade', loadChildren: 'app/_new-tecnoscopio-v2/new-privacidade/new-privacidade.module#NewPrivacidadeModule' },
      { path: 'diagramas', loadChildren: 'app/_new-tecnoscopio-v2/new-lista-diagramas/new-lista-diagramas.module#NewListaDiagramasModule' },

      //Login do suporte
      { path: 'login-user', loadChildren: 'app/_new-tecnoscopio-v2/new-login-user-by-admin/new-login-user-by-admin.module#NewLoginUserByAdminModule', canActivate: [LoginUserByAdminGuard] },
    ]
  },

  { path: 'testes', loadChildren: 'app/_new-tecnoscopio-v2/site-de-testes/site-de-testes.module#SiteDeTestesModule' }, //NÃO COMENTAR - AUTENTICAÇÃO EM HOMOLOGAÇÃO
  { path: 'trocar-documento', loadChildren: 'app/_new-tecnoscopio-v2/new-trocar-documento/new-trocar-documento.module#NewTrocarDocumentoModule' },

  {
    path: 'certificado', component: NewNavBarComponent, children: [
      { path: '', loadChildren: 'app/_new-tecnoscopio-v2/new-validar-certificado/new-validar-certificado.module#NewValidarCertificadoModule' },
    ]
  },
  { path: 'diagrama', loadChildren: 'app/_nt-f4bric/diagrama.module#DiagramaModule' },
  { path: "diagrama/user", loadChildren: 'app/_new-diagrama/new-fabric.module#NewFabricModule' },

  { path: "login-by-token/:token", component: VerificaTokenComponent, resolve: { token: LoginTokenGuard } },
  { path: "login-by-token/app/:token", component: VerificaTokenAppComponent, resolve: { token: LoginTokenGuard } },
  { path: "login-by-token/mobile/:token", component: VerificaTokenMobileComponent, resolve: { token: LoginTokenGuard } },

  { path: `${environment.url_version}/login`, redirectTo: `${environment.url_version}/user/login`, pathMatch: 'full' },
  { path: `${environment.url_version}/cadastro`, redirectTo: `${environment.url_version}/user/register`, pathMatch: 'full' },
  { path: '**', redirectTo: environment.url_version, pathMatch: 'full' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
