import { AppService } from '../../../app.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { jsPDF } from 'jspdf';
import 'svg2pdf.js';
import { AlertService } from '../../alerts/alert.service';

@Component({
  selector: 'app-certificate-generator',
  templateUrl: './certificate-generator.component.html',
  styleUrls: ['./certificate-generator.component.scss']
})
export class CertificateGeneratorComponent implements OnInit {

  dataToReturn;
  mobileAppFlag: boolean = false;

  constructor(private dialog: MatDialogRef<CertificateGeneratorComponent>,
    private _dialog: MatDialog,
    private alertService: AlertService,
    private appService: AppService,
    @Inject(MAT_DIALOG_DATA) private data: Data_Interface) {
    dialog.beforeClose().subscribe(() => dialog.close(this.dataToReturn));
  }

  ngOnInit() {
    this.appService.getMobileApp().subscribe(flag => {
      this.mobileAppFlag = flag;
    });
    this.downloadAsPDF();
  }

  async downloadAsPDF() {
    await setTimeout(async () => {
      var nameX: any = document.getElementById('getNameX');
      nameX.setAttribute("x", this.countX(this.data.aluno.length));
      var svg: any = document.getElementById('svg-container');
      const doc: any = new jsPDF('l', 'mm', [1238, 875]);
      return await doc.svg(svg, { aling: 'center' }).then(async () => {
        if (!this.mobileAppFlag) {
          await doc.save(`certificado.pdf`);
        } else {
          const pdfBlob = doc.output('blob');
          this.sendPdfMobileApp(pdfBlob);
        }
        this.dialog.close();
        this._dialog.closeAll();
        try {
          this.alertService.downloadCertificado(this.data.cursoId).subscribe((res: any) => {
            if (res.code == 200) {
              //Sucesso
              return
            } else {
              //Falha
              return
            }
          },
            (error) => {
              //Falha
              return
            });
        } catch (error) {
          //Falha
          return
        }
      });
    }, 2000);
  }

  sendPdfMobileApp(pdfDataUri) {
    const message = {
      type: 'pdf-download-website',
      payload: pdfDataUri
    };

    window.parent.postMessage(message, '*');
  }

  countX(x: any) {
    return -Math.round(-954.54545 + 68.18182 * x)
  }

}

interface Data_Interface {
  cursoId: string;
  aluno: string;
  nome_curso: string;
  professor_nome: string;
  carga_total: string;
  data_inicio: string;
  data_conclusao: string;
  cnpj: string;
  site: string;
  codigo: string;
}
