import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges, OnDestroy, HostListener } from '@angular/core';
import panzoom from 'panzoom';

@Component({
  selector: 'app-image-zoom-modal',
  templateUrl: './image-zoom-modal.component.html',
  styleUrls: ['./image-zoom-modal.component.scss']
})
export class ImageZoomModalComponent implements OnInit, OnChanges, OnDestroy {
  @Input() show: boolean = false;
  @Input() imageUrl: string = '';
  @Input() title: string = '';
  @Input() altText: string = 'Imagem Ampliada';

  @Output() close = new EventEmitter<void>();

  @ViewChild('panzoomContainer', { static: false }) panzoomContainer: ElementRef;

  panzoomInstance: any = null;
  zoomLevel: number = 1;

  constructor() { }

  ngOnInit() {
    this.setupKeyboardListeners();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.show && changes.show.currentValue === true) {
      document.body.style.overflow = 'hidden';
      setTimeout(() => {
        this.initPanzoom();
      }, 100);
    } else if (changes.show && changes.show.currentValue === false) {
      document.body.style.overflow = '';
      this.disposePanzoom();
    }
  }

  ngOnDestroy() {
    this.disposePanzoom();
    document.body.style.overflow = '';
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.show) {
      this.closeModal();
    }
  }

  setupKeyboardListeners() {
    if (this.panzoomContainer && this.panzoomContainer.nativeElement) {
      this.panzoomContainer.nativeElement.addEventListener('wheel', (e: Event) => {
        if (this.show) {
          e.stopPropagation();
        }
      });
    }
  }

  closeModal() {
    this.close.emit();
  }

  initPanzoom() {
    if (this.panzoomContainer && this.panzoomContainer.nativeElement) {
      this.disposePanzoom();

      this.panzoomInstance = panzoom(this.panzoomContainer.nativeElement, {
        maxZoom: 4,
        minZoom: 0.5,
        bounds: true,
        boundsPadding: 0.1,
        zoomDoubleClickSpeed: 1,
        smoothScroll: false,
        onTouch: (e) => {
          const target = e.target as HTMLElement;
          if (target.closest('.modal-close') ||
            target.closest('.floating-close') ||
            target.closest('.zoom-controls')) {
            return false;
          }
          return true;
        }
      });

      this.zoomLevel = 1;
      this.panzoomInstance.moveTo(0, 0);
      this.panzoomInstance.zoomAbs(0, 0, 1);
      this.panzoomInstance.on('zoom', (e) => {
        this.updateZoomLevel();
      });
    }
  }

  updateZoomLevel() {
    if (this.panzoomInstance) {
      const { scale } = this.panzoomInstance.getTransform();
      this.zoomLevel = scale;
    }
  }

  disposePanzoom() {
    if (this.panzoomInstance) {
      this.panzoomInstance.dispose();
      this.panzoomInstance = null;
    }
    this.zoomLevel = 1;
  }

  zoomIn() {
    if (this.panzoomInstance && this.zoomLevel < 4) {
      this.zoomLevel = Math.min(this.zoomLevel + 0.5, 4);
      const { x, y } = this.panzoomInstance.getTransform();
      this.panzoomInstance.zoomAbs(x, y, this.zoomLevel);
      this.updateZoomLevel();
    }
  }

  zoomOut() {
    if (this.panzoomInstance && this.zoomLevel > 0.5) {
      this.zoomLevel = Math.max(this.zoomLevel - 0.5, 0.5);
      const { x, y } = this.panzoomInstance.getTransform();
      this.panzoomInstance.zoomAbs(x, y, this.zoomLevel);
      this.updateZoomLevel();
    }
  }

  resetZoom() {
    if (this.panzoomInstance) {
      this.zoomLevel = 1;
      this.panzoomInstance.moveTo(0, 0);
      this.panzoomInstance.zoomAbs(0, 0, 1);
      this.updateZoomLevel();
    }
  }
}